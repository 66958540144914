import { Formik } from 'formik';
import * as Yup from "yup";
import React, { useState } from 'react';
import { Alert, Button, Col, Form, Input, InputGroup, Label, Row, Spinner } from 'reactstrap';
import { PostData } from '../../../services/FetchData';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';

const CollectWebsite = (props) => {
	const [redirect, setRedirect]	= useState(false),
		[errorMessages, setErrorMessages]	= useState("");

	const saveStoreInformation	= (values, actions) => {
		PostData("ms1", "api/v1/website/saveStore", {...values, storeType: "magento"}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setRedirect(true)
				}else if( result.status === "failure" ){
					setErrorMessages(result.message)
				}else{
					setErrorMessages(result.message.message)
				}
			}
			actions.setSubmitting(false)
		})
	}

	if( redirect ){
		return <Navigate to="/user/dashboard" replace />
	}

	return (
		<>
			<section className='choosePlatformHeader'>
				<div className='container'>
					<Row className="align-items-center justify-content-center">
						<Col xs={"auto"}>
							<h2 className='mt-5'>Store Information</h2>
						</Col>
					</Row>
				</div>
			</section>
			<section className='shopifyAppInstallationGuide mt-5'>
				<div className='container'>
					<Row className='align-items-center justify-content-center'>
						<Col xs={12} lg={6} className='text-center d-none d-lg-block'>
							<img src={require("./../../../assets/images/integration.png")} alt="integration" className='img-fluid' />
						</Col>
						<Col xs={12} lg={6}>
							{errorMessages !== "" && (
								<Alert color="danger">
									{errorMessages}
								</Alert>
							)}
							<div className='storeInformationWrapper'>
								<Formik
									initialValues={{storeURL: "", consumerKey: "", consumerSecret: "", accessToken: "", accessTokenSecret: ""}}
									onSubmit={(values, actions) => {
										saveStoreInformation(values, actions)
									}}
									validationSchema={Yup.object().shape({
										//storeName: Yup.string().required("Store name is required"),
										storeURL: Yup.string().required("Store url is required").url("Please enter valid store url"),
										consumerKey: Yup.string().required("Consumer key is required"),
										consumerSecret: Yup.string().required("Consumer secret is required"),
										accessToken: Yup.string().required("Access token is required"),
										accessTokenSecret: Yup.string().required("Access token secret is required")
									})}
								>
									{props => {
										const {
											values,
											touched,
											errors,
											isSubmitting,
											//setSubmitting,
											handleChange,
											handleBlur,
											handleSubmit
										} = props;
										return (
											<Form role="form" onSubmit={handleSubmit} noValidate>
												{/* <div className={`position-relative mb-5 ${(errors.storeName && touched.storeName && "error_field")}`}>
													<Label className="input-group-alternative-label" >
														Store Name
													</Label>
													<InputGroup className="input-group-alternative">
														<Input
															placeholder="Store Name"
															type="text"
															name="storeName"
															value={values.storeName}
															onChange={handleChange}
															onBlur={handleBlur}
															autofill='false'
															className={
																"col" || (errors.storeName && touched.storeName && "error")
															}
														/>
													</InputGroup>
													{errors.storeName && touched.storeName && (
														<div className="input-feedback">{errors.storeName}</div>
													)}
												</div> */}
												<div className={`position-relative mb-5 ${(errors.storeURL && touched.storeURL && "error_field")}`}>
													<Label className="input-group-alternative-label" >
														Store URL
													</Label>
													<InputGroup className="input-group-alternative">
														{/* <InputGroupText>
															<span className="material-icons-outlined">lock_open</span>
														</InputGroupText> */}
														<Input
															placeholder="Store URL"
															type={"text"}
															name="storeURL"
															value={values.storeURL}
															onChange={handleChange}
															onBlur={handleBlur}
															className={
																"col" ||
																(errors.storeURL && touched.storeURL && "error")
															}
															autofill="false"
														/>
													</InputGroup>
													{errors.storeURL && touched.storeURL && (
														<div className="input-feedback">{errors.storeURL}</div>
													)}
												</div>
												<div className={`position-relative mb-5 ${(errors.consumerKey && touched.consumerKey && "error_field")}`}>
													<Label className="input-group-alternative-label" >
														Consumer Key
													</Label>
													<InputGroup className="input-group-alternative">
														{/* <InputGroupText>
															<span className="material-icons-outlined">lock_open</span>
														</InputGroupText> */}
														<Input
															placeholder="Consumer Key"
															type={"text"}
															name="consumerKey"
															value={values.consumerKey}
															onChange={handleChange}
															onBlur={handleBlur}
															className={
																"col" ||
																(errors.consumerKey && touched.consumerKey && "error")
															}
															autofill="false"
														/>
													</InputGroup>
													{errors.consumerKey && touched.consumerKey && (
														<div className="input-feedback">{errors.consumerKey}</div>
													)}
												</div>
												<div className={`position-relative mb-5 ${(errors.consumerSecret && touched.consumerSecret && "error_field")}`}>
													<Label className="input-group-alternative-label" >
														Consumer Secret
													</Label>
													<InputGroup className="input-group-alternative">
														{/* <InputGroupText>
															<span className="material-icons-outlined">lock_open</span>
														</InputGroupText> */}
														<Input
															placeholder="Consumer Secret"
															type={"text"}
															name="consumerSecret"
															value={values.consumerSecret}
															onChange={handleChange}
															onBlur={handleBlur}
															className={
																"col" ||
																(errors.consumerSecret && touched.consumerSecret && "error")
															}
															autofill="false"
														/>
													</InputGroup>
													{errors.consumerSecret && touched.consumerSecret && (
														<div className="input-feedback">{errors.consumerSecret}</div>
													)}
												</div>
												<div className={`position-relative mb-5 ${(errors.accessToken && touched.accessToken && "error_field")}`}>
													<Label className="input-group-alternative-label" >
														Access Token
													</Label>
													<InputGroup className="input-group-alternative">
														{/* <InputGroupText>
															<span className="material-icons-outlined">lock_open</span>
														</InputGroupText> */}
														<Input
															placeholder="Access Token"
															type={"text"}
															name="accessToken"
															value={values.accessToken}
															onChange={handleChange}
															onBlur={handleBlur}
															className={
																"col" ||
																(errors.accessToken && touched.accessToken && "error")
															}
															autofill="false"
														/>
													</InputGroup>
													{errors.accessToken && touched.accessToken && (
														<div className="input-feedback">{errors.accessToken}</div>
													)}
												</div>
												<div className={`position-relative mb-5 ${(errors.accessTokenSecret && touched.accessTokenSecret && "error_field")}`}>
													<Label className="input-group-alternative-label" >
														Access Token Secret
													</Label>
													<InputGroup className="input-group-alternative">
														{/* <InputGroupText>
															<span className="material-icons-outlined">lock_open</span>
														</InputGroupText> */}
														<Input
															placeholder="Access Token Secret"
															type={"text"}
															name="accessTokenSecret"
															value={values.accessTokenSecret}
															onChange={handleChange}
															onBlur={handleBlur}
															className={
																"col" ||
																(errors.accessTokenSecret && touched.accessTokenSecret && "error")
															}
															autofill="false"
														/>
													</InputGroup>
													{errors.accessTokenSecret && touched.accessTokenSecret && (
														<div className="input-feedback">{errors.accessTokenSecret}</div>
													)}
												</div>
												<div className="d-flex justify-content-center">
													{isSubmitting ? (
														<Button className="cta authCta" color="primary" type="button" disabled>
															<Spinner
																className="white mr-1"
																size="sm"
																color="light"
															/>
															<span className="ml-2 d-inline-block">Loading</span>
														</Button>
													) : (
														<Button className=" cta authCta " color="primary" type="submit">Save</Button>
													)}											
												</div>												
											</Form>)
									}}
								</Formik>
							</div>
						</Col>
					</Row>
				</div>
			</section>
		</>
	);
}

export default CollectWebsite;