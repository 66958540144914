import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableItem = ({ id, children }) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({ id }),
		[isDragging, setIsDragging] = useState(false)

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		//  padding: "10px",
		margin: "5px 0",
		backgroundColor: "white",
		border: "1px solid lightgray",
		borderRadius: "4px",
		cursor: "grab",
	}

	const handleMouseDown = () => {
		setIsDragging(false);
	};

	const handleMouseUp = (e) => {
		if (isDragging) {
			e.preventDefault(); // Prevent triggering any click event while dragging
		}
	};

	const handleClick = (e) => {
		if (!isDragging) {
			console.log(`Draggable item clicked: ${id}`);
		}
	};

	return (
		<div 
			ref={setNodeRef}
			style={style}
			onMouseDown={handleMouseDown}
			onMouseMove={() => setIsDragging(true)}
			onMouseUp={handleMouseUp}
			onClick={handleClick}
			{...attributes}
			{...listeners}
		>
			{children}
		</div>
	);
};

export default DraggableItem;
