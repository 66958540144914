import React, { useContext, useEffect, useRef, useState } from 'react';
import MenuHeader from './MenuHeader';
import { ProductContext } from './../../../store';
import { PostData } from '../../../services/FetchData';
import DetailSkeleton from '../../Skeleton/DetailSkeleton';
import { FormGroup, Label, Input, } from 'reactstrap';

const GeneralCategory = ({ type }) => {
	const [pageLoader, setPageLoader] = useState(true),
		divRef = useRef(null),
		[height, setHeight] = useState("100vh"),
		{
			setCategories,
			categoryId,
			description,
			magentoStoreViews,
			defaultStoreView,
			setDefaultStoreView,
			websitePlatform,
			editCategoryMerchandising,
			loadVisualEditor,
			setEnableSave,
			setProductPage,
			setProducts,
			setBuried,
			setHidden,
			setPromoted,
			setBuriedProducts,
			setHiddenProducts,
			setOriginalProducts,
			setPinnedItems,
			setHiddenProductIndex,
			setPinnedProductIndex,
			setDescription,
			magentoStoreViewsLoader,
			categories,
			setCategoryId,
			currentWebsiteId,
		} = useContext(ProductContext);

	useEffect(() => {
		const calculateHeight = () => {
			if (divRef.current) {
				const topPosition = divRef.current.getBoundingClientRect().top
				const newHeight = `calc(100vh - ${topPosition}px)`
				setHeight(newHeight)
			}
		}
		// Calculate height initially
		calculateHeight()

		// Recalculate height on window resize
		window.addEventListener("resize", calculateHeight)
		return () => {
			window.removeEventListener("resize", calculateHeight)
		}
	}, [divRef, loadVisualEditor])

	useEffect(() => {
		if( currentWebsiteId && !magentoStoreViewsLoader ){
			setCategories([])
			setCategoryId(null)
			getCategoryDetails();
		}
	}, [defaultStoreView, currentWebsiteId, magentoStoreViewsLoader]);

	const updateCategoryId = (selectedCategory) => {
		setEnableSave(false)
		setProductPage(1)
		setProducts([])
		setBuried([])
		setHidden([])
		setPromoted([])
		setBuriedProducts([])
		setHiddenProducts([])
		setOriginalProducts([])
		setPinnedItems({})
		setHiddenProductIndex({})
		setPinnedProductIndex({})
		setCategoryId(selectedCategory);
	}

	const getCategoryDetails = async () => {
		setPageLoader(true)
		PostData("ms2", "api/v1/merchandising/getCategory", { websiteId: currentWebsiteId, storeView: defaultStoreView }).then(response => {
			if (response !== "Invalid" && response !== "undefined") {
				if (response.status === "success") {
					setCategories(response.data.categories)
				}
			}
			setPageLoader(false)
		})
	}
	return (
		<React.Fragment>
			<MenuHeader title={"General"} />
			<div className='sideBarMenuGridContainer' ref={divRef} style={{ height: height, position: "relative" }}>
				<div className='menuContentWrapper'>
					<div style={{ display: 'flex', flexDirection: "column", padding: "20px" }}  >
						{pageLoader ?
							<DetailSkeleton />
						:

							<div>
								{websitePlatform === "magento" && Object.keys(magentoStoreViews).length > 1 &&
									<FormGroup style={{marginBottom: "20px"}}>
										{type === "new" ?
											<>
												<Label for="storeViews">Store Views</Label>
												<Input
													type="select"
													value={defaultStoreView}
													name='storeView'
													onChange={(e) => setDefaultStoreView(e.target.value)}
												>
													{Object.keys(magentoStoreViews).map((key, index) => {
														return(
															<option value={key}>{magentoStoreViews[key]}</option>
														)
													})}
												</Input>
											</>
										: ( type === "edit" && editCategoryMerchandising ) &&
											<>
												<Label className="font-weight-bold">Store Views</Label>
												<p>{magentoStoreViews[editCategoryMerchandising.storeView]}</p>
											</>
										
										}
									</FormGroup>
								}
								<FormGroup style={{marginBottom: "20px"}}>
									{type === "new" ?
										<>
											<Label for="categories">Category(Required)</Label>
											<Input type='select' name='categoryId' id='categoryId' value={categoryId} onChange={(e) => updateCategoryId(e.target.value)}>
												<option value="SelectOption">Select Option</option>
												{categories.map((category) => (
													<option key={category.categoryId} value={category.categoryId}>
														{category.categoryName} - {category.categoryUrl}
													</option>
												))}
											</Input>
										</>
									: ( type === "edit" && editCategoryMerchandising ) &&
										<>
											<Label className="font-weight-bold">Category</Label>
											<p style={{lineBreak: "anywhere"}}>{editCategoryMerchandising.categoryName}</p>
										</>
									}
								</FormGroup>
								<FormGroup style={{ marginBottom: "20px" }}>
									<Label for="description">Description</Label>
									<Input
										id="description"
										type="textarea"
										placeholder="Description"
										rows={3}
										value={description}
										onChange={(e) => setDescription(e.target.value)}
									/>
								</FormGroup>
							</div>
						}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default GeneralCategory;
