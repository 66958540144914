import React, { useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from 'reactstrap';

export const SortableItem =  React.memo((props) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({id: props.id});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const renameBtnClicked = () => {
		props.onRenameClick(props.index)
	}

	const deactivateBtnClicked = () => {
		props.onDeactivateBtnClicked(props.index)
	}

	return (
		<li className='cbSortListItem'>
			<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
				<div className='sortableLeft'>
					<svg width="7" height="11" viewBox="0 0 7 11" xmlns="http://www.w3.org/2000/svg"><g fill="#D3DBE2"><circle cx="1.5" cy="1.5" r="1.5"></circle><circle cx="5.5" cy="1.5" r="1.5"></circle><circle cx="1.5" cy="5.5" r="1.5"></circle><circle cx="5.5" cy="5.5" r="1.5"></circle><circle cx="1.5" cy="9.5" r="1.5"></circle><circle cx="5.5" cy="9.5" r="1.5"></circle></g></svg>
					<p className='mb-0' color='secondary60'>{props.id.title}</p>
				</div>
			</div>
			<div className='sortableRight'>
				<Button variant="utility" onClick={(e) => { e.preventDefault(); deactivateBtnClicked()}}>
					<svg viewBox="0 0 20 20" focusable="false" aria-hidden="true"><path d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z" fillRule="evenodd"></path></svg>
					Deactivate
				</Button>
				<Button variant="utility" onClick={(e) => { e.preventDefault(); renameBtnClicked()}}>
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M30.276 1.722C29.168.612 27.69 0 26.12 0s-3.044.61-4.153 1.72L4.294 19.29c-.105.105-.185.23-.235.368l-4 11c-.13.355-.047.756.214 1.03C.466 31.892.73 32 1 32c.098 0 .196-.014.293-.044l9.95-3.052c.155-.047.297-.133.413-.248l18.62-18.62C31.39 8.925 32 7.447 32 5.877c0-1.57-.61-3.046-1.724-4.156zM10.092 27.165L6.368 28.31c-.217-.638-.555-1.202-1.016-1.663-.4-.4-.866-.71-1.356-.96L5.7 21H8v2c0 .553.447 1 1 1h1.765l-.673 3.165zm14.72-14.494L12.628 24.856l.35-1.647c.062-.296-.012-.603-.202-.837-.19-.234-.475-.37-.776-.37h-2v-2c0-.552-.448-1-1-1H7.422L19.315 7.175l.012.01c.732-.732 1.707-1.135 2.742-1.135s2.01.403 2.74 1.136 1.14 1.707 1.14 2.743c0 1.035-.404 2.008-1.138 2.74zm4.05-4.05l-.932.934c-.09-1.43-.683-2.76-1.703-3.782-1.02-1.022-2.354-1.614-3.787-1.703l.938-.932.002-.002C24.11 2.403 25.085 2 26.12 2s2.01.403 2.742 1.136C29.596 3.87 30 4.843 30 5.878c0 1.037-.402 2.01-1.138 2.743zm-6.57-.327l-10 10c-.39.39-.39 1.023 0 1.414.195.195.452.293.708.293s.51-.098.707-.293l10-10c.39-.39.39-1.023 0-1.414-.392-.39-1.023-.39-1.414 0z"></path></svg>
					Rename
				</Button>
			</div>
		</li>
	);
})