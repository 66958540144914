import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import DateRangePickerComponent from "../../../components/DaterangePicker";
import { format, startOfMonth } from "date-fns";
import { PostData } from "../../../services/FetchData";
import moment from "moment";
import { Col, Row, Spinner } from "reactstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CredentialSkeleton from "../../../components/Skeleton/CredentialSkeleton";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);
const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		x: {
			grid: {
				display: false,
			},
		},
		y: {
			beginAtZero: true,
		},
	},
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const data = {
	labels,
	datasets: [
		{
			label: "Search",
			data: [0, 0, 0, 0, 0, 0, 0],
			fill: false,
			borderColor: "#2b4cc5",
			tension: 0.3,
		},
	],
};

const data1 = {
	labels,
	datasets: [
		{
			label: "Total Click",
			data: [0, 0, 0, 0, 0, 0, 0],
			fill: false,
			borderColor: "#d88d49",
			tension: 0.3,
		},
	],
};

const data2 = {
	labels,
	datasets: [
		{
			label: "Total Cost",
			data: [0, 0, 0, 0, 0, 0, 0],
			fill: false,
			borderColor: "#008000",
			tension: 0.3,
		},
	],
};

const Dashboard = () => {
	const [activeItem, setActiveItem] = useState("Dashboard");
	const [activeTab, setActiveTab] = useState(1), // Default to "Search Phrase"
		[activeGraph, setActiveGraph] = useState("search"),
		[analyticsData, setAnalyticsData] = useState({}),
		[totalSearch, setTotalSearch]	= useState(0),
		[totalClicked, setTotalClicked]	= useState(0),
		[totalClickedAmount, setTotalClickedAmount]	= useState(0),
		[usageLoader, setUsageLoader] = useState(true),
		[popularSearch, setPopularSearch] = useState([]),
		[searchGraph, setSearchGraph] = useState(null),
		[clickGraph, setClickGraph] = useState(null),
		[priceGraph, setPriceGraph] = useState(null),
		[noItemFound, setNoItemFound] = useState([]),
		[productClicked, setProductClicked] = useState([]),
		[popularResult, setPopularResult] = useState([]),
		[graphData, setGraphData] = useState(data),
		[dateRange, setDateRange] = useState({
													startDate: startOfMonth(new Date()),
													endDate: new Date(),
													key: "selection",
												});

	useEffect(() => {
		getWebsiteAnalytics();
	}, [dateRange]);

	const getWebsiteAnalytics = async () => {

		let websiteId	= Cookies.get("_website_id"),
			postData	= {
							websiteId,
							startDate: format(dateRange.startDate, "yyyy-MM-dd"),
							endDate: format(dateRange.endDate, "yyyy-MM-dd")
						}
		setUsageLoader(true)
		PostData("ms1", "api/v1/analytics/get", postData).then(response => {
			if( response !== "Invalid" && response !== undefined ){
				if( response.status === "success" ){
					setTotalSearch(response.data.totalSearch);
					setTotalClicked(response.data.totalClicked)
					setTotalClickedAmount(response.data.totalClickedAmount)
					setPopularSearch(response.data.popularSearchKeyData);
					setNoItemFound(response.data.popularSearchNoResultData);
					setPopularResult(response.data.popularSearchProductData);
					setProductClicked(response.data.clickedProductData)
					const graphDataValue = response.data.dateWiseSearchRecord,
						clickGraphDataValue = response.data.dateWiseClickRecord,
						priceGraphDataValue = response.data.dateWisePriceRecord,
						graphDataObject = {},
						clickGraphDataObject = {},
						priceGraphDataObject = {},
						previousSevenDays = {},
						previousDataRecord = [],
						previousDataClickRecord = [],
						previousDataPriceRecord = [];
					for (let i = 0; i < graphDataValue.length; i++) {
						let currentGraphDataValue = graphDataValue[i];
						graphDataObject[currentGraphDataValue._id] = currentGraphDataValue.count;
					}
					for (let i = 0; i < clickGraphDataValue.length; i++) {
						let currentGraphDataValue = clickGraphDataValue[i];
						clickGraphDataObject[currentGraphDataValue._id] = currentGraphDataValue.count;
					}
					for (let i = 0; i < priceGraphDataValue.length; i++) {
						let currentGraphDataValue = priceGraphDataValue[i];
						priceGraphDataObject[currentGraphDataValue._id] = currentGraphDataValue.total_price;
					}
					let currentDate = new Date(dateRange.startDate);
					while (currentDate <= new Date(dateRange.endDate)) {
						const key = moment(currentDate).clone().format("YYYY-MM-DD"),
							value = moment(currentDate).clone().format("MMM DD");
						previousSevenDays[key] = value;
						if (typeof graphDataObject[key] !== "undefined") {
							previousDataRecord.push(graphDataObject[key]);
						} else {
							previousDataRecord.push(0);
						}
						if (typeof clickGraphDataObject[key] !== "undefined") {
							previousDataClickRecord.push(clickGraphDataObject[key]);
						} else {
							previousDataClickRecord.push(0);
						}
						if (typeof priceGraphDataObject[key] !== "undefined") {
							previousDataPriceRecord.push(priceGraphDataObject[key]);
						} else {
							previousDataPriceRecord.push(0);
						}
						currentDate.setDate(currentDate.getDate() + 1);
					}

					data1.labels = Object.values(previousSevenDays);
					data1.datasets[0].data = previousDataClickRecord
					console.log(previousDataClickRecord, "Click Graph");
					setClickGraph({ ...data1 })
					data2.labels = Object.values(previousSevenDays);
					data2.datasets[0].data = previousDataPriceRecord
					console.log(previousDataPriceRecord, "Price Graph");
					setPriceGraph({ ...data2 })
					data.labels = Object.values(previousSevenDays);
					data.datasets[0].data = previousDataRecord;
					setActiveGraph("search")
					setGraphData({ ...data });
					setSearchGraph({ ...data })
				}else if( response.status === "failure" ){
					toast.error(response.message)
				}else{
					toast.error(response.message.message)
				}
			}
			setUsageLoader(false)
		})
	};

	return (
		<React.Fragment>
			<div>
				<Row className="mt-3 align-items-center justify-content-between">
					<Col xs="auto">
						<h2>Dashboard</h2>
					</Col>
					<Col xs={"auto"}>
						<DateRangePickerComponent
							onSelect={(range) => setDateRange(range)}
						/>
					</Col>
				</Row>
				<Row className="mt-3 justify-content-between">
					<div className="row mt-3">
						<div style={{display: "flex",flexDirection: "row",justifyContent: "start",gap: "16px",flexWrap: "nowrap",width: "100%",}}>
							<div className={`boxOne search ${activeGraph === "search" ? "activeItem" : ""}`} style={{boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",cursor: "pointer",borderTopColor: "#2b4cc5",}} onClick={() => {setGraphData(searchGraph);setActiveGraph("search");}}>
								<div className="top-row">
									<span>Total searches</span>
									<i className="fa fa-search" aria-hidden="true" style={{ marginLeft: "8px" }} />
								</div>
								{usageLoader ? (
									<div>
										<Spinner style={{ width: "20px", height: "20px" }} />{" "}
									</div>
								) : (
									<b>{totalSearch}</b>
								)}
							</div>
							<div className={`boxOne click ${activeGraph === "click" ? "activeItem" : "" }`} style={{boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",cursor: "pointer", borderTopColor: "#d88d49",}} onClick={() => { setGraphData(clickGraph);setActiveGraph("click");}}>
								<div className="top-row">
									<span>Total Clicks</span>
								</div>
								{usageLoader ? (
									<div>
										<Spinner style={{ width: "20px", height: "20px" }} />{" "}
									</div>
								) : (
									<b>{totalClicked}</b>
								)}
							</div>
							<div className={`boxTwo price ${activeGraph === "price" ? "activeItem" : "" }`} style={{boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",cursor: "pointer",borderTopColor: "#008000",}} onClick={() => {setGraphData(priceGraph);setActiveGraph("price");}}>
								<div className="top-row">
									<span>Total cost of clicked products</span>
								</div>
								{usageLoader ? (
									<div>
										<Spinner style={{ width: "20px", height: "20px" }} />{" "}
									</div>
								) : (
									<b>${totalClickedAmount}</b>
								)}
							</div>
						</div>
					</div>
				</Row>
				<div className="row mt-5">
					{usageLoader ? (
						<CredentialSkeleton height={40} />
					) : (
						<>
							{/* <Bar options={options} data={graphData} /> */}
							<Line data={graphData} options={options} />
						</>
					)}
				</div>
				<div className="allTableContainer mt-5">
					<div className="row">
						{/* Popular Searches Section */}
						<div className="analyticsStack col-6">
							<h4 style={{ marginBottom: "12px" }}>Popular Searches</h4>
							{!usageLoader && productClicked.length === 0 && (
								<div className="noDataStatsText">No data yet</div>
							)}
							<table className="analyticsTable">
								<thead>
									<tr>
										<td className="analyticsTableCell">Search Term</td>
										<td className="analyticsTableCell">Search Count</td>
									</tr>
								</thead>
								<tbody>
									{Array.from({ length: 5 }, (_, index) => index).map(
										(item, index) => {
											return (
												<tr className="analyticsTableRow" key={index}>
													<td className="analyticsTableCell">
														{usageLoader ? (
															<Skeleton height={20} />
														) : typeof popularSearch[index] !==
															"undefined" ? (
															popularSearch[index]._id
														) : (
															<>&nbsp;</>
														)}
													</td>
													<td className="analyticsTableCell">
														{usageLoader ? (
															<Skeleton height={20} />
														) : typeof popularSearch[index] !==
															"undefined" ? (
															popularSearch[index].count
														) : (
															""
														)}
													</td>
												</tr>
											);
										}
									)}
								</tbody>
							</table>
						</div>

						{/* No Items Found Section */}
						<div className="analyticsStack col-6">
							<h4 style={{ marginBottom: "12px" }}>No Items Found</h4>
							{!usageLoader && noItemFound.length === 0 && (
								<div className="noDataStatsText">No data yet</div>
							)}
							<table className="analyticsTable">
								<thead>
									<tr>
										<td className="analyticsTableCell">Search Term</td>
										<td className="analyticsTableCell">Frequency</td>
									</tr>
								</thead>
								<tbody>
									{Array.from({ length: 5 }, (_, index) => index).map(
										(item, index) => {
											return (
												<tr className="analyticsTableRow" key={index}>
													<td className="analyticsTableCell">
														{usageLoader ? (
															<Skeleton height={20} />
														) : typeof noItemFound[index] !==
															"undefined" ? (
															noItemFound[index]._id
														) : (
															<>&nbsp;</>
														)}
													</td>
													<td className="analyticsTableCell">
														{usageLoader ? (
															<Skeleton height={20} />
														) : typeof noItemFound[index] !==
															"undefined" ? (
															noItemFound[index].count
														) : (
															""
														)}
													</td>
												</tr>
											);
										}
									)}
								</tbody>
							</table>
						</div>
					</div>

					<div className="row mt-5">
						{/* Popular Products Section */}
						<div className="analyticsStack col-6">
							<h4 style={{ marginBottom: "12px" }}>Popular Products</h4>
							{!usageLoader && productClicked.length === 0 && (
								<div className="noDataStatsText">No data yet</div>
							)}
							<table className="analyticsTable">
								<thead>
									<tr>
										<td className="analyticsTableCell">Product Name</td>
										<td className="analyticsTableCell">View Count</td>
									</tr>
								</thead>
								<tbody>
									{Array.from({ length: 5 }, (_, index) => index).map(
										(item, index) => {
											return (
												<tr className="analyticsTableRow" key={index}>
													<td className="analyticsTableCell">
														{usageLoader ? (
															<Skeleton height={10} />
														) : typeof popularResult[index] !==
															"undefined" ? (
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<img
																	src={popularResult[index].image}
																	alt={popularResult[index].name}
																	width="35"
																	height="35"
																	style={{
																		objectFit: "cover",
																		marginRight: "8px",
																	}}
																/>
																<span>{popularResult[index].name}</span>
															</div>
														) : (
															<>&nbsp;</>
														)}
													</td>
													<td className="analyticsTableCell">
														{usageLoader ? (
															<Skeleton height={10} />
														) : typeof popularResult[index] !==
															"undefined" ? (
															popularResult[index].count
														) : (
															""
														)}
													</td>
												</tr>
											);
										}
									)}
								</tbody>
							</table>
						</div>

						{/* Popular Clicks Section */}
						<div className="analyticsStack col-6">
							<h4 style={{ marginBottom: "12px" }}>Product Clicks</h4>
							{!usageLoader && productClicked.length === 0 && (
								<div className="noDataStatsText">No data yet</div>
							)}
							<table className="analyticsTable">
								<thead>
									<tr>
										<td className="analyticsTableCell">Product Name</td>
										<td className="analyticsTableCell">Click Count</td>
									</tr>
								</thead>
								<tbody>
									{Array.from({ length: 5 }, (_, index) => index).map(
										(item, index) => {
											return (
												<tr className="analyticsTableRow" key={index}>
													<td className="analyticsTableCell">
														{usageLoader ? (
															<Skeleton height={10} />
														) : typeof productClicked[index] !==
															"undefined" ? (
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<img
																	src={productClicked[index].image}
																	alt={productClicked[index].name}
																	width="35"
																	height="35"
																	style={{
																		objectFit: "cover",
																		marginRight: "8px",
																	}}
																/>
																<span>{productClicked[index].name}</span>
															</div>
														) : (
															<>&nbsp;</>
														)}
													</td>
													<td className="analyticsTableCell">
														{usageLoader ? (
															<Skeleton height={10} />
														) : typeof productClicked[index] !==
															"undefined" ? (
															productClicked[index].count
														) : (
															""
														)}
													</td>
												</tr>
											);
										}
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Dashboard;
