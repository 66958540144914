import { he } from "date-fns/locale"
import React, { useState, useRef, useEffect } from "react"
import { Input, ListGroup, ListGroupItem } from "reactstrap"

const Autocomplete = ({ suggestions, placeholder, onUpdate, value = "" }) => {
	const [query, setQuery] = useState(value)
	const [filteredSuggestions, setFilteredSuggestions] = useState([])
	const [showSuggestions, setShowSuggestions] = useState(false)
	const [showAll, setShowAll] = useState(false)
	const [dropdownStyle, setDropdownStyle] = useState({})
	const inputRef = useRef(null)
	const dropdownRef = useRef(null)

	useEffect(() => {
		if (showSuggestions && inputRef.current) {
			const rect = inputRef.current.getBoundingClientRect()
			setDropdownStyle({
				position: "fixed",
				width: `${rect.width}px`,
				backgroundColor: "white",
				zIndex: 1000,
				boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
				maxHeight: "200px",
				overflowY: "auto",
			})
		}
	}, [showSuggestions])

	useEffect(() => {
		const handleClickOutside = (event) => {
		  if ( dropdownRef.current && !dropdownRef.current.contains(event.target) && inputRef.current && !inputRef.current.contains(event.target) ) {
			 setShowSuggestions(false)
		  }
		}
  
		document.addEventListener("mousedown", handleClickOutside)
		return () => document.removeEventListener("mousedown", handleClickOutside)
	 }, [])

	const handleInputChange = (event) => {
		const value = event.target.value
		setQuery(value)
		filterSuggestions(value)
	}

	const filterSuggestions = (value) => {
		if (value.length > 0) {
			const filtered = suggestions.filter((suggestion) =>
				suggestion.toLowerCase().includes(value.toLowerCase())
			)
			setFilteredSuggestions(filtered)
		} else {
			setFilteredSuggestions(suggestions) // Show all initially
		}
		setShowSuggestions(true)
		setShowAll(false) // Reset "Show more" state
	}

	const handleSelect = (suggestion) => {
		setQuery(suggestion)
		onUpdate(suggestion)
		setShowSuggestions(false)
	}

	const handleShowMore = () => {
		setShowAll(true)
	}

	const handleFocus = () => {
		filterSuggestions(query)
	}

	return (
		<div>
			<Input
				type="text"
				value={query}
				onChange={handleInputChange}
				onFocus={handleFocus} // Show dropdown on focus
				placeholder={placeholder?? "Search"}
				innerRef={inputRef}
			/>
			{showSuggestions && filteredSuggestions.length > 0 && (
				<ListGroup style={dropdownStyle} innerRef={dropdownRef}>
					{(showAll ? filteredSuggestions : filteredSuggestions.slice(0, 10)).map(
						(suggestion, index) => (
							<ListGroupItem
								key={index}
								action
								onClick={() => handleSelect(suggestion)}
							>
								{suggestion}
							</ListGroupItem>
						)
					)}
					{!showAll && filteredSuggestions.length > 10 && (
						<ListGroupItem
							className="text-center text-primary"
							action
							onClick={handleShowMore}
						>
							Show {filteredSuggestions.length - 10} more
						</ListGroupItem>
					)}
				</ListGroup>
			)}
		</div>
	)
}

export default Autocomplete