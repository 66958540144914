
import React, { useContext, useEffect, useRef, useState } from 'react'
import MenuHeader from './MenuHeader'
import { ProductContext } from './../../../store'
import DetailSkeleton from '../../Skeleton/DetailSkeleton'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import { Form } from 'react-router-dom'
import { PostData } from '../../../services/FetchData'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRemove, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import Autocomplete from '../../AutoComplete'
import { set } from 'date-fns'

const areFiltersEqual = (arr1, arr2) => {
	if (arr1.length !== arr2.length) return false;
	return arr1.every((item, index) =>
		item.facet === arr2[index]?.facet && item.value === arr2[index]?.value
	);
}

const GeneralLandingPage = ({ type, pageLoader }) => {
	const divRef = useRef(null),
		[height, setHeight] = useState("100vh"),
		[landingPageLoader, setLandingPageLoader] = useState(true),
		[landingPages, setLandingPages] = useState([]),
		[modalLandingPageId, setModalLandingPageId] = useState(null),
		[modalLandingPageSearchPhrase, setModalLandingPageSearchPhrase] = useState(""),
		[modalLandingPageFilter, setModalLandingPageFilter] = useState([]),
		[facetFields, setFacetFields] = useState([]),
		[facetValues, setFacetValues] = useState([]),
		[isDisabled, setIsDisabled] = useState(true),
		[addNewFilterValue, setAddNewFilterValue] = useState(false),
		[facetFieldObject, setFacetFieldObject] = useState({}),
		{
			currentWebsiteId,
			loadVisualEditor,
			defaultStoreView,
			websitePlatform,
			magentoStoreViews,
			setDefaultStoreView,
			landingPageSearchPhrase,
			setLandingPageSearchPhrase,
			landingPageFilter,
			setLandingPageFilter,
			landingPageId,
			setLandingPageId,
			editLandingPageMerchandising,
			searchPhraseModal,
			setSearchPhraseModal,
			setEnableSave,
			setProductPage,
			setProducts,
			setBuried,
			setHidden,
			setPromoted,
			setBuriedProducts,
			setHiddenProducts,
			setOriginalProducts,
			setPinnedItems,
			setHiddenProductIndex,
			setPinnedProductIndex,
			setLoadOriginalProduct
		} = useContext(ProductContext)

	useEffect(() => {
		const calculateHeight = () => {
			if (divRef.current) {
				const topPosition = divRef.current.getBoundingClientRect().top
				const newHeight = `calc(100vh - ${topPosition}px)`
				setHeight(newHeight)
			}
		}
		// Calculate height initially
		calculateHeight()

		// Recalculate height on window resize
		window.addEventListener("resize", calculateHeight)
		return () => {
			window.removeEventListener("resize", calculateHeight)
		}
	}, [divRef, loadVisualEditor])

	useEffect(() => {
		setLandingPageSearchPhrase("")
		setLandingPageFilter([])
		setLandingPageId(null)
		getAllLandingPages()
	},[defaultStoreView])

	useEffect(() => {
		const hasChanges = modalLandingPageId !== landingPageId || modalLandingPageSearchPhrase !== landingPageSearchPhrase || !areFiltersEqual(modalLandingPageFilter, landingPageFilter);
		const hasEmptyFields = modalLandingPageFilter.length > 1 ? modalLandingPageFilter.some(filter => !filter.facet || !filter.value) : false;
		const isFormIncomplete = !modalLandingPageSearchPhrase && modalLandingPageFilter.length === 1 && (!modalLandingPageFilter[0].facet || !modalLandingPageFilter[0].value);
		setIsDisabled(hasEmptyFields || !hasChanges || !modalLandingPageId || isFormIncomplete);
		if( modalLandingPageFilter.length > 1 ){
			setAddNewFilterValue(true)
		}else if( modalLandingPageFilter.length === 1 && modalLandingPageFilter[0].facet !== "" && modalLandingPageFilter[0].value !== "" ){
			setAddNewFilterValue(true)
		}else{
			console.log("Add New Filter Value", addNewFilterValue)
			setAddNewFilterValue(false)
		}
	}, [modalLandingPageFilter, modalLandingPageId, modalLandingPageSearchPhrase, landingPageFilter, landingPageId, landingPageSearchPhrase])

	const getAllLandingPages = () => {
		setLandingPageLoader(true)
		PostData("ms2", "api/v1/merchandising/magentoPages", {websiteId: currentWebsiteId, storeView: defaultStoreView}).then((response) => {
			if( response !== "Invalid" && response !== "undefined" ){
				if( response.status === "success" ){
					setLandingPages(response.data.magentoPages)
					setFacetFieldObject(response.data.facetValueObject)
					setFacetFields(response.data.facetFields)
				}else if( response.status === "failure" ){
					toast.error(response.message)
				}else{
					toast.error(response.message.message)
				}
			}
			setLandingPageLoader(false)
		})
	}

	const openSearchPhraseModal = () => {
		if( !searchPhraseModal ){
			setModalLandingPageId(landingPageId)
			setModalLandingPageSearchPhrase(landingPageSearchPhrase)
			let landingPageFilterLocal = [...landingPageFilter]
			if( landingPageFilter.length === 0 ){
				landingPageFilterLocal.push({id: Date.now(),facet: "", value: ""})
			}
			setModalLandingPageFilter(landingPageFilterLocal)
		}
		setSearchPhraseModal((prev) => !prev)
	}

	const applyFilter = () => {
		setEnableSave(false)
		setProductPage(1)
		setProducts([])
		setBuried([])
		setHidden([])
		setPromoted([])
		setBuriedProducts([])
		setHiddenProducts([])
		setOriginalProducts([])
		setPinnedItems({})
		setHiddenProductIndex({})
		setPinnedProductIndex({})
		setLandingPageId(modalLandingPageId)
		setLandingPageSearchPhrase(modalLandingPageSearchPhrase)
		setLoadOriginalProduct(true)
		let modalLandingPageFilterLocal	= [...modalLandingPageFilter]
		const sortedFilters = modalLandingPageFilterLocal.sort((a, b) => 
			a.facet.localeCompare(b.facet)
	  );
		setLandingPageFilter(sortedFilters)
		setSearchPhraseModal((prev) => !prev)
		// if( loadProducts ){
		// 	loadProducts()
		// }
	}

	const groupedFilters = landingPageFilter.filter((filter) => filter.value && filter.facet).reduce((acc, filter) => {
		acc[filter.facet] = acc[filter.facet] || [];
		acc[filter.facet].push(filter.value);
		return acc;
	 }, {});

	return (
		<React.Fragment>
			<MenuHeader title={"Trigger"} />
			<div className='sideBarMenuGridContainer' ref={divRef} style={{ height: height, position: "relative" }}>
				<div className='menuContentWrapper'>
					<div style={{ display: 'flex', flexDirection: "column", padding: "20px" }}  >
						{(pageLoader || landingPageLoader ) ?
							<DetailSkeleton />
						:
							<div>
								{websitePlatform === "magento" && Object.keys(magentoStoreViews).length > 1 &&
									<FormGroup style={{marginBottom: "20px"}}>
										{type === "new" ?
											<>
												<Label for="storeViews">Store Views</Label>
												<Input
													type="select"
													value={defaultStoreView}
													name='storeView'
													onChange={(e) => setDefaultStoreView(e.target.value)}
												>
													{Object.keys(magentoStoreViews).map((key, index) => {
														return(
															<option value={key}>{magentoStoreViews[key]}</option>
														)
													})}
												</Input>
											</>
										: ( type === "edit" && editLandingPageMerchandising ) &&
											<>
												<Label className="font-weight-bold">Store Views</Label>
												<p>{magentoStoreViews[editLandingPageMerchandising.storeView]}</p>
											</>
										
										}
									</FormGroup>
								}
								{/* <FormGroup style={{ marginBottom: "20px" }}>
									{type === "new" ? (
										<>
											<Label for="landingPageId">Landing Page</Label>
											<Input
												type="select"
												value={landingPageId}
												name='landingPageId'
												id={"landingPageId"}
												onChange={(e) => setLandingPageId(e.target.value)}
											>
												{landingPages.map((landingPage, index) => {
													return(
														<option key={index} value={landingPage.landing_page_id}>{landingPage.title.toUpperCase()}</option>
													)
												})}
											</Input>
										</>
									) : (type === "edit" && editLandingPageMerchandising) && (
										<>
											<Label className="font-weight-bold">Landing Page</Label>
											<p>{editLandingPageMerchandising.landignPage}</p>
										</>
									)}
								</FormGroup> */}
								<FormGroup className='d-flex flex-column gap-1'>
									<Label className='font-weight-bold'><b>Conditions</b></Label>
									{( landingPageFilter.length == 0 && landingPageSearchPhrase === "" ) ?
										<Button color='primary' style={{"backgroundColor": "#ffffff40", "borderColor": "#d6d6e740", color: "#333", gap: "10px", borderWidth: "1px", borderRadius: "3px", padding: "8px", maxWidth: "fit-content"}} onClick={openSearchPhraseModal}>
											<div className='d-flex justify-content-between align-items-center' style={{gap: "10px"}}>
												<div style={{background: "linear-gradient(to bottom,#e8faff,#b9efff)", boxShadow: "0 4px 8px -2px #21243d40,inset 0 -2px 2px #89e5ff08", width: "2rem", height: "2rem", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center"}}>
													<FontAwesomeIcon icon={faSearch} color='#2cc8f7' />
												</div>
												<span>Set condition(s)</span>
											</div>
										</Button>
									:
										<>
											<div class="d-flex flex-column">
												<div class="mx--4">
													<div class="d-flex flex-column gap-1 h-auto">
														<div class="d-flex flex-column conditionOuterWrapper">
															<div class="d-flex align-items-center justify-content-between conditionInnerWrapper">
																<div class="d-flex flex-column overflow-hidden">
																	{landingPageSearchPhrase && (
																		<div class="d-flex flex-column">
																			<div>
																				<div class="d-flex align-items-center">
																					<span class="shrink-0">Query is</span>
																					<span class="d-inline-flex align-items-center justify-content-center queryPhraseTextWrap">
																						<span class="truncateClass">{landingPageSearchPhrase}</span>
																					</span>
																				</div>
																				<div></div>
																			</div>
																		</div>
																	)}
																	{landingPageFilter.length > 0 &&
																		<div className="space-y-1">
																			{Object.entries(groupedFilters).map(([facet, values], mapIndex) => (
																				<div key={facet} className="space-y-1">																					
																						{values.map((value, index) => (
																							<div className="d-flex align-items-center overflow-hidden">
																								<span className="shrink-0">
																									{index === 0 ?
																										<>{mapIndex === 0 ? "with" : "and"} Filter</>
																									:
																										<>OR</>
																									}
																								</span>
																								<span key={index} className="d-inline-flex align-items-center justify-content-center overflow-hidden queryPhraseTextWrap">
																									<span className="truncateClass">
																										<div className="truncateClass">{facet} is {value}</div>
																									</span>
																								</span>
																							</div>
																						))}
																				</div>
																			))}
																		</div>
																	}
																	<p class="d-flex align-items-center space-x-2">
																		<span class="shrink-0">with Landing Page</span>
																		<span class="d-inline-flex align-items-center justify-content-center overflow-hidden landingPageTextWrap">
																			<span class="truncateClass">{landingPages.filter((landingPage) => landingPage.landing_page_id === landingPageId)[0]?.title}</span>
																		</span>
																	</p>
																</div>
																<div class="d-flex justify-content-center align-items-center">
																	<button id="rulePickerSummaryEdit" type="button" class="btn d-inline-flex align-items-center justify-content-center" aria-label="Edit" data-state="closed" onClick={() => openSearchPhraseModal()}>
																		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="lucide lucide-pen ">
																			<path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path>
																		</svg>
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									}
								</FormGroup>
							</div>
						}
					</div>
				</div>
			</div>
			{searchPhraseModal && (
				<div className='searchPhraseModal'>
					<div className='sidePanel with-effect'>
						<header className='d-flex align-items-center justify-content-between'>
							<div class="SidePanel-close" style={{order: "1"}}>
								<button id="sidepanel-close-button" type="button" aria-label="Close" data-state="closed" onClick={() => openSearchPhraseModal()} >
									<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x ">
										<path d="M18 6 6 18"></path><path d="m6 6 12 12"></path>
									</svg>
								</button>
							</div>
							<div class="SidePanel-title">
								<h3>Define the condition</h3>
							</div>
						</header>
						<div>
							<div className='d-flex flex-column gap-3'>
								<FormGroup className='d-flex flex-column gap-1'>
									{type === "new" ? (
										<>
											<Label for="modalLandingPageId"><b>Landing Page</b></Label>
											<Input
												type="select"
												value={modalLandingPageId}
												name='modalLandingPageId'
												id={"modalLandingPageId"}
												onChange={(e) => setModalLandingPageId(e.target.value)}
											>
												<option value="">Select Landing Page</option>
												{landingPages.map((landingPage, index) => {
													return(
														<option key={index} value={landingPage.landing_page_id}>{landingPage.title?.toUpperCase()}</option>
													)
												})}
											</Input>
										</>
									) : (type === "edit" && editLandingPageMerchandising) && (
										<>
											{console.log(editLandingPageMerchandising)}
											<Label className="font-weight-bold"><b>Landing Page</b></Label>
											<p>{editLandingPageMerchandising.landingPage?.toUpperCase()}</p>
										</>
									)}
								</FormGroup>
								<FormGroup className='d-flex flex-column gap-1'>
									<Label for="searchPhrase"><b>Search Phrase</b></Label>
									<Input
										type="text"
										value={modalLandingPageSearchPhrase}
										placeholder='Enter search phrase'
										name='searchPhrase'
										id={"searchPhrase"}
										onChange={(e) => setModalLandingPageSearchPhrase(e.target.value)}
									/>
								</FormGroup>
								<FormGroup className='d-flex flex-column gap-1'>
									<Label>Filters</Label>
									<div className='d-flex flex-column gap-2'>
										{modalLandingPageFilter.map((filter, index) => {
											return(
												<div className='filterItemWrapOuter'>
													<div className='filterItemWrapInner'> 
														<div className='filterItemWrapLeft'>
															<div className='filterFacetName'>
																<div className='autocompleteWrap'>
																	<Autocomplete
																		key={index+"-facet"+"-"+filter.id}
																		placeholder="Name (e.g. Size)"
																		value={filter.facet}
																		onUpdate={(suggestion) => {
																			let newFilter = [...modalLandingPageFilter]
																			newFilter[index].facet = suggestion
																			setModalLandingPageFilter(newFilter)
																		}}
																		suggestions={facetFields}
																	/>
																</div>
															</div>
															<div className='filterFacetOperation1'>is</div>
														</div>
														<div className='filterItemWrapRight'>
															<div className='filterFacetValue'>
																<div className='autocompleteWrap'>
																	<Autocomplete
																		key={index+"-value"+"-"+filter.id}
																		placeholder="Value (e.g. 10)"
																		value={filter.value}
																		onUpdate={(suggestion) => {
																			let newFilter = [...modalLandingPageFilter]
																			newFilter[index].value = suggestion
																			setModalLandingPageFilter(newFilter)
																		}}
																		suggestions={facetFieldObject[filter.facet]?? []}
																	/>
																</div>
															</div>
															{modalLandingPageFilter.length > 1 && (
																<div className='filterFacetOperation'>
																	<Button color='danger' onClick={() => {
																		let newFilter = [...modalLandingPageFilter]
																		newFilter.splice(index, 1)
																		setModalLandingPageFilter(newFilter)
																	}}><FontAwesomeIcon icon={faTrash} /></Button>
																</div>
															)}
														</div>
													</div>
												</div>
											)
										})}
										{addNewFilterValue && (
											<button
												id="ruleConditionAdd"
												type="button"
												class="d-inline-flex justify-content-center align-items-center px-3 mt-4 h-8" 
												onClick={() => {
													let newFilter = [...modalLandingPageFilter]
													newFilter.push({id: Date.now(),facet: "", value: ""})
													setModalLandingPageFilter(newFilter)
												}}
											>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="lucide lucide-plus stl-shrink-0">
													<path d="M5 12h14"></path>
													<path d="M12 5v14"></path>
												</svg>
												<span class="whitespace-nowrap px-1">Add another filter value</span>
											</button>
										)}
									</div>
								</FormGroup>
							</div>
							<div class="SidePanel-footer">
								<Button color='primary' id="ruleConditionApply" type="button" onClick={() => applyFilter()} disabled={isDisabled} className="btn d-inline-flex justify-content-center align-items-items-center px-3">
									<span class="whitespace-nowrap px-1">Apply</span>
								</Button>
							</div>
						</div>
					</div>
					<div id="sidepanel-dimmer-close-button" class="SidePanel-dimmer" onClick={() => openSearchPhraseModal()}></div>
				</div>	
			)}		
		</React.Fragment>
	)
}
export default GeneralLandingPage