import React, { useEffect, useState } from "react";
import { ProductContext } from "./index";
import { GetData, PostData } from "../services/FetchData";

const ProductProvider = props => {
	const [userData, setUserData]	= useState({}),
		[userLoader, setUserLoader]	= useState(true),
		[websiteData, setWebsiteData]	= useState({}),
		[currentWebsiteId, setCurrentWebsiteId]	= useState(null),
		[websiteLoader, setWebsiteLoader]	= useState(true),
		[addPageOpen, setAddPageOpen] = useState(false),
		[loadVisualEditor, setLoadVisualEditor]	= useState(false),
		[products, setProducts]	= useState([]),
		[categoryId, setCategoryId]	= useState(null),
		[originalProductOrder, setOriginalProductOrder]	= useState([]),
		[categories, setCategories]	= useState([]),
		[description, setDescription]	= useState(""),
		[productLoader, setProductLoader]	= useState(false),
		[productCount, setProductCount]	= useState(null),
		[productPage, setProductPage]	= useState(1),
		[pagePerProducts, setPagePerProducts]	= useState(10),
		[enableSave, setEnableSave]	= useState(false),
		[rowPerProducts, setRowPerProducts]	= useState(4),
		[shopCurrency, setShopCurrency]	= useState('USD'),
		[hasMore, setHasMore]	= useState(false),
		[productOrder, setProductOrder]	= useState([]),
		[promoted, setPromoted]	= useState([]),
		[buried, setBuried]	= useState([]),
		[hidden, setHidden]	= useState([]),
		[buriedProducts, setBuriedProducts]	= useState([]),
		[hiddenProducts, setHiddenProducts]	= useState([]),
		[saveLoader, setSaveLoader]	= useState(false),
		[categoryIds, setCategoryIds]	= useState([]),
		[pinnedItems, setPinnedItems]	= useState({}),
		[originalProducts, setOriginalProducts]	= useState([]),
		[hiddenProductIndex, setHiddenProductIndex]	= useState({}),
		[activeItem, setActiveItem] = useState(null),
		[editCategoryMerchandising, setEditCategoryMerchandising]	= useState(null),
		[searchPhrase, setSearchPhrase]	= useState(""),
		[editSearchPhraseMerchandising, setEditSearchPhraseMerchandising]	= useState(null),
		[editLandingPageMerchandising, setEditLandingPageMerchandising]	= useState(null),
		[exact, setExact]	= useState(true),
		[platForm, setPlatForm]	= useState(""),
		[editorPageFlag, setEditorPageFlag]	= useState(false),
		[pinnedProductIndex, setPinnedProductIndex]	= useState({}),
	 	[activeMenu, setActiveMenu]	= useState('general'),
		[magentoStoreViewsLoader, setMagentoStoreViewsLoader]	= useState(true),
		[websitePlatform, setWebsitePlatform]	= useState(""),
		[defaultStoreView, setDefaultStoreView]	= useState(""),
		[magentoStoreViews, setMagentoStoreViews]	= useState({}),
		[landingPageSearchPhrase, setLandingPageSearchPhrase]	= useState(""),
		[landingPageId, setLandingPageId]	= useState(null),
		[searchPhraseModal, setSearchPhraseModal] = useState(false),
		[loadOriginalProduct, setLoadOriginalProduct]	= useState(false),
		[landingPageFilter, setLandingPageFilter]	= useState([]);
	
	useEffect(() => {
		if( websiteData ){
			if( typeof websiteData[currentWebsiteId] !== "undefined" ){
				setWebsitePlatform(websiteData[currentWebsiteId].websiteType)
				if( websiteData[currentWebsiteId].websiteType === "magento" ){
					PostData("ms2", "api/v1/magento/getStoreViews", {websiteId:currentWebsiteId}).then(result => {
						if( result !== "Invalid" && result !== "undefined" ){
							if( result.status === "success" ){
								let storeViewObj	= result.data.storeViewObj
								setMagentoStoreViews(storeViewObj)
								if( Object.keys(storeViewObj).length > 0 ){
									setDefaultStoreView(Object.keys(storeViewObj)[0])
								}
								setMagentoStoreViewsLoader(false)
							}
						}
					})
				}
			}
		}
	}, [currentWebsiteId, websiteData])
	
	const store = {
		editorPageFlag,
		setEditorPageFlag,
		activeMenu,
		setActiveMenu,
		userData,
		setUserData,
		userLoader,
		setUserLoader,
		websiteData,
		websiteLoader,
		setWebsiteLoader,
		originalProductOrder,
		setOriginalProductOrder,
		setWebsiteData,
		currentWebsiteId,
		setCurrentWebsiteId,
		originalProducts,
		setOriginalProducts,
		addPageOpen,
		setAddPageOpen,
		products,
		setProducts,
		description,
		setDescription,
		categoryId,
		setCategoryId,
		categories,
		setCategories,
		productLoader,
		setProductLoader,
		productPage,
		setProductPage,
		productCount,
		setProductCount,
		enableSave,
		setEnableSave,
		pagePerProducts,
		setPagePerProducts,
		rowPerProducts,
		setRowPerProducts,
		shopCurrency,
		setShopCurrency,
		hiddenProductIndex,
		setHiddenProductIndex,
		hasMore,
		setHasMore,
		productOrder,
		setProductOrder,
		promoted,
		setPromoted,
		buried,
		setBuried,
		hidden,
		setHidden,
		buriedProducts,
		setBuriedProducts,
		hiddenProducts,
		setHiddenProducts,
		saveLoader,
		setSaveLoader,
		categoryIds,
		setCategoryIds,
		activeItem,
		setActiveItem,
		editCategoryMerchandising,
		setEditCategoryMerchandising,
		loadVisualEditor,
		setLoadVisualEditor,
		pinnedItems,
		setPinnedItems,
		searchPhrase,
		setSearchPhrase,
		pinnedProductIndex,
		setPinnedProductIndex,
		editSearchPhraseMerchandising,
		setEditSearchPhraseMerchandising,
		exact,
		setExact,
		platForm,
		setPlatForm,
		magentoStoreViews,
		magentoStoreViewsLoader,
		websitePlatform,
		defaultStoreView,
		setDefaultStoreView,
		landingPageSearchPhrase,
		setLandingPageSearchPhrase,
		landingPageFilter,
		setLandingPageFilter,
		landingPageId,
		setLandingPageId,
		editLandingPageMerchandising,
		setEditLandingPageMerchandising,
		searchPhraseModal,
		setSearchPhraseModal,
		loadOriginalProduct,
		setLoadOriginalProduct
	}
	return <ProductContext.Provider value={store}>{props.children}</ProductContext.Provider>;
}

export default ProductProvider;