import React from 'react';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faMagnifyingGlass, faBullhorn, faRectangleAd, faAngleRight, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

// Disable auto CSS addition by FontAwesome
config.autoAddCss = false;

const Merchandising = () => {
	const navigate = useNavigate(); // Initialize the navigate function
	const searchData = [
		{
			header: 'Categories Merchandising',
			key: 'category',
			icon: faLayerGroup,
			description: 'An intuitive visual editor to easily curate and organize category products.',
			buttonTxt: 'Edit Categories Merchandising',
		},
		{
			header: 'Search Merchandising',
			key: 'search',
			icon: faMagnifyingGlass,
			description: 'Visual editor to curate Search results and Autocomplete products',
			buttonTxt: 'Edit Search Merchandising',
		},
		{
			header: 'Landing Page Merchandising',
			key: 'landingPage',
			icon: faWindowRestore,
			description: 'An intuitive visual editor to easily curate and organize landing page products.',
			buttonTxt: 'Edit Landing Page Merchandising',
		},
		// {
		// 	header: 'Promo Tiles',
		// 	key: 'promoTitles',
		// 	icon: faBullhorn,
		// 	description: 'Increase conversion with promotional messages in collection and search results',
		// 	buttonTxt: 'Edit Promo Tiles',
		// },
		// {
		// 	header: 'Banners',
		// 	key: 'banners',
		// 	icon: faRectangleAd,
		// 	description: 'Increase conversion with banners in collection and search results',
		// 	buttonTxt: 'Edit Banners',
		// },
	]

	// Handle when an item is clicked
	const loadParticularMerchandisingItem = (itemKey) => {
		navigate(`/search/merchandising/${itemKey}`);
	}

	// Render the merchandising list or the selected item component
	return (
		<div className="merchandisingContainer">
			<ul>
				{searchData.map((item) => (
					<li key={item.key}>
						<div
							className="mearchandisingItemWrapper"
							onClick={() => loadParticularMerchandisingItem(item.key)}
						>
							<div className="merchandisingItemInner">
								<FontAwesomeIcon icon={item.icon} size={'3x'} color="#d7d7d7" />
								<h4>{item.header}</h4>
								<p>{item.description}</p>
							</div>
							<div className="merchandisingItemBottom">
								<div>{item.buttonTxt}</div>
								<FontAwesomeIcon icon={faAngleRight} />
							</div>
						</div>
					</li>
				))}
				<li>
					<div className="mearchandisingItemWrapper">
						<div className="merchandisingItemInner">
							<div style={{display: "flex", gap: "10px", alignItems: "center"}}>
								<FontAwesomeIcon icon={faBullhorn} size={"3x"} color='#d7d7d7' />
								<div className='comingSoon'>Coming Soon</div>
							</div>
							<h4>Promo Tiles</h4>
							<p>Increase conversion with promotional messages in collection and search results</p>
						</div>
						<div className="merchandisingItemBottom">
							<div>Edit Promo Tiles</div>
							<FontAwesomeIcon icon={faAngleRight} />
						</div>
					</div>
				</li>
				<li>
					<div className="mearchandisingItemWrapper">
						<div className="merchandisingItemInner">
							<div style={{display: "flex", gap: "10px", alignItems: "center"}}>
								<FontAwesomeIcon icon={faRectangleAd} size={"3x"} color='#d7d7d7' />
								<div className='comingSoon'>Coming Soon</div>
							</div>
							<h4>Banners</h4>
							<p>Increase conversion with banners in collection and search results</p>
						</div>
						<div className="merchandisingItemBottom">
							<div>Edit Banners</div>
							<FontAwesomeIcon icon={faAngleRight} />
						</div>
					</div>
				</li>
			</ul>
		</div>
	);
}

export default Merchandising;
