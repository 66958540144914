import React, { useContext, useEffect, useState } from "react";
import InstantSearchComponent from "../../../../components/Search/SearchOptions/Magento/InstantSearch";
import DetailSkeleton from "../../../../components/Skeleton/DetailSkeleton";
import { ProductContext } from "../../../../store";

const InstantSearch = () => {
	const [platForm, setPlatForm]	= useState(""),
		[loader, setLoader]	= useState(true),
		{
			currentWebsiteId,
			websiteData,
			websiteLoader
		}	= useContext(ProductContext)
	
	

	useEffect(() => {
		if( !websiteLoader && websiteData[currentWebsiteId] ){
			setPlatForm(websiteData[currentWebsiteId].websiteType)
			setLoader(false)
		}else{
			setLoader(true)
		}
	}, [websiteLoader, currentWebsiteId, websiteData])

	return (
		<React.Fragment>
			{loader ?
				<DetailSkeleton />
			:
				<>
					{platForm === "magento" &&
						<InstantSearchComponent />
					}
				</>
			}
			
		</React.Fragment>
	);
};

export default InstantSearch;
