import React from "react";
import { useDroppable } from "@dnd-kit/core";

const DroppableContainer = ({ children }) => {
  const { setNodeRef, isOver } = useDroppable({ id: "droppable" });

  const style = {
    minWidth: "200px",
    minHeight: "200px",
   //  padding: "10px",
   //  backgroundColor: isOver ? "lightblue" : "lightgray",
   //  border: "1px solid darkgray",
   //  borderRadius: "8px",
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};

export default DroppableContainer;
