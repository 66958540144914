import { faAdd, faLayerGroup, faMinus, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import Switch from "react-switch";
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./../../../../components/Search/SortableItem";
import { ProductContext } from "../../../../store";
import { PostData } from "../../../../services/FetchData";
import { toast } from "react-toastify";
import DetailSkeleton from "../../../Skeleton/DetailSkeleton";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { FacetItem } from "../../FacetItem";


const InstantSearch = () => {
	const [loader, setLoader] = useState(true),
		[saveLoader, setSaveLoader] = useState(false),
		[instantSearchConfig, setInstantSearchConfig] = useState(null),
		[toggleLoader, setToggleLoader] = useState(false),
		[instantSortActiveFields, setInstantSortActiveFields] = useState([]),
		[instantSortInactiveFields, setInstantSortInactiveFields] = useState([]),
		[instantFacetActiveFields, setInstantFacetActiveFields] = useState([]),
		[instantFacetInactiveFields, setInstantFacetInactiveFields] = useState([]),
		[renameModalFlag, setRenameModalFlag] = useState(false),
		[currentSortField, setCurrentSortField] = useState({}),
		[currentSortIndex, setCurrentSortIndex] = useState(null),
		[editModalFlag, setEditModalFlag] = useState(false),
		[currentFacetField, setCurrentFacetField] = useState({}),
		[currentFacetIndex, setCurrentFacetIndex] = useState(null),
		{
			currentWebsiteId
		} = useContext(ProductContext)

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	)

	useEffect(() => {
		getInstantSearchConfig()
	}, [])

	const getInstantSearchConfig = () => {
		PostData("ms2", "api/v1/magento/getInstantSearchConfig", { websiteId: currentWebsiteId }).then(result => {
			if (result !== "Invalid" && result !== "undefined") {
				if (result.status === "success") {
					console.log(result.data)
					setInstantSearchConfig(result.data.instantSearchConfig)
					setInstantSortActiveFields(result.data.instantSortActiveFields)
					setInstantSortInactiveFields(result.data.instantSortInactiveFields)
					setInstantFacetActiveFields(result.data.instantFacetActiveFields)
					setInstantFacetInactiveFields(result.data.instantFacetInactiveFields)
					setLoader(false)
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
		})
	}

	const toggleInstantSearch = () => {
		setToggleLoader(true)
		PostData("ms2", "api/v1/magento/toggleInstantSearch", { websiteId: instantSearchConfig.websiteId, status: !instantSearchConfig.status }).then(result => {
			if (result !== "Invalid" && result !== "undefined") {
				if (result.status === "success") {
					setInstantSearchConfig({
						...instantSearchConfig,
						status: !instantSearchConfig.status
					})
					toast.success(result.message)
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
			setToggleLoader(false)
		})
	}

	const changeInstantSearchConfig = (indexName, value) => {
		setInstantSearchConfig({
			...instantSearchConfig,
			[indexName]: value
		})
	}

	const findIndexByKey = (array, key, value) => {
		return array.findIndex(item => item[key] === value);
	}

	const handleSortDragEnd = (event) => {
		const { active, over } = event;
		if (active.id.sortFieldId !== over.id.sortFieldId) {
			let sortFieldsLocal = [...instantSortActiveFields],
				oldIndex = findIndexByKey(sortFieldsLocal, "sortFieldId", active.id.sortFieldId),
				newIndex = findIndexByKey(sortFieldsLocal, "sortFieldId", over.id.sortFieldId);
			sortFieldsLocal = arrayMove(sortFieldsLocal, oldIndex, newIndex)
			setInstantSortActiveFields(sortFieldsLocal)
		}
	}

	const handleFacetDragEnd = (event) => {
		const { active, over } = event;
		if (active.id.facetFieldId !== over.id.facetFieldId) {
			let facetFieldsLocal = [...instantFacetActiveFields],
				oldIndex = findIndexByKey(facetFieldsLocal, "facetFieldId", active.id.facetFieldId),
				newIndex = findIndexByKey(facetFieldsLocal, "facetFieldId", over.id.facetFieldId);
			facetFieldsLocal = arrayMove(facetFieldsLocal, oldIndex, newIndex)
			setInstantFacetActiveFields(facetFieldsLocal)
		}
	}

	const closeSortTitleModal = () => {
		setCurrentSortField({})
		setCurrentSortIndex(null)
		setRenameModalFlag(false)
	}

	const closeEditFacetModal = () => {
		setCurrentFacetField({})
		setCurrentFacetIndex(null)
		setEditModalFlag(false)
	}

	const saveSortTitle = () => {
		let activeSortLocal = [...instantSortActiveFields]
		activeSortLocal[currentSortIndex]["title"] = currentSortField.title
		setInstantSortActiveFields(activeSortLocal)
		setCurrentSortField({})
		setCurrentSortIndex(null)
		setRenameModalFlag(false)
	}

	const saveFacetValue = () => {
		let activeFacetLocal = [...instantFacetActiveFields]
		activeFacetLocal[currentFacetIndex] = currentFacetField
		setInstantFacetActiveFields(activeFacetLocal)
		setCurrentFacetField({})
		setCurrentFacetIndex(null)
		setEditModalFlag(false)
	}

	const renameModalTrigger = (index) => {
		setCurrentSortIndex(index)
		setCurrentSortField(instantSortActiveFields[index])
		setRenameModalFlag(true)
	}

	const editFacetTrigger = (index) => {
		setCurrentFacetIndex(index)
		setCurrentFacetField(instantFacetActiveFields[index])
		setEditModalFlag(true)
	}

	const handleSortTitle = (value) => {
		setCurrentSortField({
			...currentSortField,
			title: value
		})
	}

	const handleFacetTitle = (value) => {
		setCurrentFacetField({
			...currentFacetField,
			title: value
		})
	}

	const searchableChange = (value) => {
		setCurrentFacetField({
			...currentFacetField,
			searchable: value
		})
	}

	const activeSortField = (index) => {
		let inactiveSortFieldLocal = [...instantSortInactiveFields],
			newActiveSortFields = inactiveSortFieldLocal[index],
			activeSortFieldLocal = [...instantSortActiveFields]
		inactiveSortFieldLocal.splice(index, 1)
		newActiveSortFields.order = activeSortFieldLocal.length - 1
		activeSortFieldLocal.push(newActiveSortFields)
		setInstantSortActiveFields(activeSortFieldLocal)
		setInstantSortInactiveFields(inactiveSortFieldLocal)
	}

	const activatedFacetField = (index) => {
		let inactiveFacetFieldLocal = [...instantFacetInactiveFields],
			newActiveFacetFields = inactiveFacetFieldLocal[index],
			activeFacetFieldLocal = [...instantFacetActiveFields]
		inactiveFacetFieldLocal.splice(index, 1)
		newActiveFacetFields.order = activeFacetFieldLocal.length - 1
		activeFacetFieldLocal.push(newActiveFacetFields)
		setInstantFacetActiveFields(activeFacetFieldLocal)
		setInstantFacetInactiveFields(inactiveFacetFieldLocal)
	}

	const deactiveSortField = (index) => {
		let inactiveSortFieldLocal = [...instantSortInactiveFields],
			activeSortFieldLocal = [...instantSortActiveFields],
			newInactiveSortFields = activeSortFieldLocal[index]
		activeSortFieldLocal.splice(index, 1)
		inactiveSortFieldLocal.push(newInactiveSortFields)
		setInstantSortActiveFields(activeSortFieldLocal)
		setInstantSortInactiveFields(inactiveSortFieldLocal)
	}

	const deactiveFacetField = (index) => {
		let inactiveFacetFieldLocal = [...instantFacetInactiveFields],
			activeFacetFieldLocal = [...instantFacetActiveFields],
			newInactiveFacetFields = activeFacetFieldLocal[index]
		activeFacetFieldLocal.splice(index, 1)
		inactiveFacetFieldLocal.push(newInactiveFacetFields)
		setInstantFacetActiveFields(activeFacetFieldLocal)
		setInstantFacetInactiveFields(inactiveFacetFieldLocal)
	}

	const facetTypeChange = (value) => {
		setCurrentFacetField({
			...currentFacetField,
			facetType: value
		})
	}

	const priceOptions = [
		{ label: 'Slider', value: 'slider' },
	];

	const otherOptions = [
		{ label: 'Conjunctive', value: 'conjunctive' },
		{ label: 'Disjunctive', value: 'disjunctive' },
	];

	const saveInstantSearchConfig = () => {
		setSaveLoader(true)
		PostData("ms2", "api/v1/magento/saveInstantSearch", {...instantSearchConfig, activeSort: instantSortActiveFields, inactiveSort: instantSortInactiveFields, activeFacet: instantFacetActiveFields, inactiveFacet: instantFacetInactiveFields}).then(result => {
			if( result !== "Invalid" && result !== "undefined" ){
				if( result.status === "success" ){
					toast.success(result.message)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setSaveLoader(false)
		})
	}

	if (loader) {
		return <DetailSkeleton />
	}

	return (
		<React.Fragment>
			<div className="search-options-card">
				<div className="card-header">
					<div className="title-text">
						<h3>InstantSearch - Results page powered by ConversionBox</h3>
						<span>
							Replace the default search page with ConversionBox's InstantSearch,
							to provide your users with instantaneous results, onscreen filtering
							(facets), and other enhanced search features.
						</span>
					</div>
					<div className="switch-container">
						<span>{instantSearchConfig.status ? "Enabled" : "Disabled"}</span>
						{toggleLoader ?
							<Spinner />
							:
							<Switch
								checked={instantSearchConfig.status}
								onChange={toggleInstantSearch}
								onColor={"#9eb3fc"}
								onHandleColor={"#3c64f4"}
								handleDiameter={25}
								uncheckedIcon={false}
								checkedIcon={false}
								boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
								activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
								height={15}
								width={40}
								className="react-switch"
								id="material-switch"
							/>
						}
					</div>
				</div>
				<div className="card-content">
					<form>
						<Row className="mb-4 align-items-center">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>No Of Products Shown</h4>
								</label>
							</Col>
							<Col xs={12} md={6}>
								<div className="input-container">
									<Button type="button" className="btn-minus" onClick={() => changeInstantSearchConfig("noOfProducts", instantSearchConfig.noOfProducts - 1)}>
										<FontAwesomeIcon icon={faMinus} />
									</Button>
									<Input type="number" id="products-count" value={instantSearchConfig.noOfProducts} onChange={(e) => { if (!isNaN(parseInt(e.target.value, 10))) { changeInstantSearchConfig("noOfProducts", e.target.value) } }} />
									<Button type="button" className="btn-plus" onClick={() => changeInstantSearchConfig("noOfProducts", instantSearchConfig.noOfProducts + 1)}>
										<FontAwesomeIcon icon={faPlus} />
									</Button>
								</div>
								<div className="buttonContext">
									<p className='mb-0'>Number of products to show in search. Defaults to 12.</p>
								</div>
							</Col>
						</Row>
						<Row className="mb-4 align-items-center">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Show Out Of Stock Products</h4>
								</label>
							</Col>
							<Col xs={12} md={6}>
								<div className="checkboxContainer">
									<Input type="checkbox" className="checkboxContainerInput" checked={instantSearchConfig.outOfStock} onChange={() => changeInstantSearchConfig("outOfStock", !instantSearchConfig.outOfStock)} />
								</div>
								<div className="buttonContext">
									<p className='mb-0'>Products marked as not in stock will show up in search results.</p>
								</div>
							</Col>
						</Row>
						<Row className="mb-4 align-items-center">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Show Price</h4>
								</label>
							</Col>
							<Col xs={12} md={6}>
								<div className="checkboxContainer">
									<Input type="checkbox" className="checkboxContainerInput" checked={instantSearchConfig.showPrice} onChange={() => changeInstantSearchConfig("showPrice", !instantSearchConfig.showPrice)} />
								</div>
							</Col>
						</Row>
						<Row className="mb-4 align-items-center">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Show SKU</h4>
								</label>
							</Col>
							<Col xs={12} md={6}>
								<div className="checkboxContainer">
									<Input type="checkbox" className="checkboxContainerInput" checked={instantSearchConfig.showSKU} onChange={() => changeInstantSearchConfig("showSKU", !instantSearchConfig.showSKU)} />
								</div>
							</Col>
						</Row>
						<Row className="mb-4 align-items-center">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Maximum Title Lines</h4>
								</label>
							</Col>
							<Col xs={12} md={6}>
								<div className="input-container">
									<Button type="button" className="btn-minus" onClick={() => changeInstantSearchConfig("titleMaxLines", instantSearchConfig.titleMaxLines - 1)}>
										<FontAwesomeIcon icon={faMinus} />
									</Button>
									<Input type="number" id="products-count" value={instantSearchConfig.titleMaxLines} onChange={(e) => { if (!isNaN(parseInt(e.target.value, 10))) { changeInstantSearchConfig("titleMaxLines", e.target.value) } }} />
									<Button type="button" className="btn-plus" onClick={() => changeInstantSearchConfig("titleMaxLines", instantSearchConfig.titleMaxLines + 1)}>
										<FontAwesomeIcon icon={faPlus} />
									</Button>
								</div>
							</Col>
						</Row>
						<Row className="mb-4 align-items-center">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Show Product Description</h4>
								</label>
							</Col>
							<Col xs={12} md={6}>
								<div className="checkboxContainer">
									<Input type="checkbox" className="checkboxContainerInput" checked={instantSearchConfig.showDescription} onChange={() => changeInstantSearchConfig("showDescription", !instantSearchConfig.showDescription)} />
								</div>
							</Col>
						</Row>
						{instantSearchConfig.showDescription &&
							<Row className="mb-4 align-items-center">
								<Col xs={12} md={4}>
									<label htmlFor="products-count">
										<h4>Maximum Description Lines</h4>
									</label>
								</Col>
								<Col xs={12} md={6}>
									<div className="input-container">
										<Button type="button" className="btn-minus" onClick={() => changeInstantSearchConfig("descriptionMaxLines", instantSearchConfig.descriptionMaxLines - 1)}>
											<FontAwesomeIcon icon={faMinus} />
										</Button>
										<Input type="number" id="products-count" value={instantSearchConfig.descriptionMaxLines} onChange={(e) => { if (!isNaN(parseInt(e.target.value, 10))) { changeInstantSearchConfig("descriptionMaxLines", e.target.value) } }} />
										<Button type="button" className="btn-plus" onClick={() => changeInstantSearchConfig("descriptionMaxLines", instantSearchConfig.descriptionMaxLines + 1)}>
											<FontAwesomeIcon icon={faPlus} />
										</Button>
									</div>
								</Col>
							</Row>
						}
						<Row className="mb-4 align-items-center">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Flip Image On Hover</h4>
								</label>
							</Col>
							<Col xs={12} md={6}>
								<div className="checkboxContainer">
									<Input type="checkbox" className="checkboxContainerInput" checked={instantSearchConfig.flipImageHover} onChange={() => changeInstantSearchConfig("flipImageHover", !instantSearchConfig.flipImageHover)} />
								</div>
							</Col>
						</Row>
						<Row className="mb-4 align-items-center">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Enable Add to Cart</h4>
								</label>
							</Col>
							<Col xs={12} md={6}>
								<div className="checkboxContainer">
									<Input type="checkbox" className="checkboxContainerInput" checked={instantSearchConfig.enableAddToCart} onChange={() => changeInstantSearchConfig("enableAddToCart", !instantSearchConfig.enableAddToCart)} />
								</div>
							</Col>
						</Row>
						<hr />
						<h6 className="mb-3 mt-3">Image</h6>
						<Row className="mb-4 align-items-center">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Width</h4>
								</label>
							</Col>
							<Col xs={12} md={6}>
								<div className="checkboxContainer">
									<Input type="text" className="form-control" value={instantSearchConfig.width ?? ""} onChange={(e) => changeInstantSearchConfig("width", e.target.value)} />
								</div>
							</Col>
						</Row>
						<Row className="mb-4 align-items-center">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Height</h4>
								</label>
							</Col>
							<Col xs={12} md={6}>
								<div className="checkboxContainer">
									<Input type="text" className="form-control" value={instantSearchConfig.height ?? ""} onChange={(e) => changeInstantSearchConfig("height", e.target.value)} />
								</div>
							</Col>
						</Row>
						<Row className="mb-4 align-items-center">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Type</h4>
								</label>
							</Col>
							<Col xs={12} md={6}>
								<div className="checkboxContainer">
									<Input type="select" className="form-control" value={instantSearchConfig.imageType ?? ""} onChange={(e) => changeInstantSearchConfig("imageType", e.target.value)}>
										<option>Select Image Type</option>
										<option value="product_base_image">Base Image</option>
										<option value="product_small_image">Small Image</option>
										<option value="product_thumbnail_image">Thumbnail</option>
									</Input>
								</div>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Sort orders</h4>
								</label>
							</Col>
							<Col xs={12} md={8}>
								<div style={{ display: "flex", flexDirection: 'column' }}>
									<p >
										Enable and configure the different product sorting options.
									</p>
									<p>
										In order to deliver our speed, our ranking is done at indexing
										time, which means that for each active sort order we create a
										new index which contains the same amount of records as the
										main one.
									</p>
									<h4 style={{ marginBottom: '10px' }} >Active</h4>
									<p >
										Use drag and drop to customise the order.
									</p>
									<p >
										Relevance (default)
									</p>
									<DndContext
										sensors={sensors}
										collisionDetection={closestCenter}
										onDragEnd={handleSortDragEnd}
									>
										<SortableContext
											items={instantSortActiveFields}
											strategy={verticalListSortingStrategy}
										>
											<ul className="cbSortList">
												{instantSortActiveFields.map((id, index) => <SortableItem key={index} index={index} id={id} onRenameClick={(index) => renameModalTrigger(index)} onDeactivateBtnClicked={(index) => deactiveSortField(index)} />)}
											</ul>
										</SortableContext>
									</DndContext>
									<div style={{ width: '100%', border: '1px solid lightgrey', marginBottom: '1rem', marginTop: "1rem" }}></div>
									<h4 >Inactive</h4>
									<div style={{ marginTop: "10px" }}>
										{instantSortInactiveFields.length > 0 ? (
											<>
												<ul style={{ display: 'flex', columnGap: '20px', rowGap: '10px', flexWrap: "wrap" }}>
													{instantSortInactiveFields.map((field, index) => {
														return (
															<li key={index}>
																<div className="inactiveSortFIeldWrapper">
																	<span title={field.title}>{field.title}</span>
																	<button

																		onClick={(e) => {
																			e.preventDefault();
																			activeSortField(index);
																		}}
																	>{<FontAwesomeIcon
																		icon={faAdd}
																		size={"1x"}
																		color="#c9c9c9"
																	/>}</button>
																</div>
															</li>
														);
													})}
												</ul>
											</>
										) : (
											<span>No inactive sort fields</span>
										)}
									</div>
								</div>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col xs={12} md={4}>
								<label htmlFor="products-count">
									<h4>Facets</h4>
								</label>
							</Col>
							<Col xs={12} md={8}>
								<div style={{ display: "flex", flexDirection: 'column' }}>
									<p>Allow customers to explore the catalog by applying multiple filters.</p>
									<h4 style={{ marginBottom: '10px' }} >Active</h4>
									<p>Use drag and drop to customise the order.</p>
									<DndContext
										sensors={sensors}
										collisionDetection={closestCenter}
										onDragEnd={handleFacetDragEnd}
									>
										<SortableContext
											items={instantSortActiveFields}
											strategy={verticalListSortingStrategy}
										>
											<ul className="cbSortList">
												{instantFacetActiveFields.map((id, index) => <FacetItem key={index} index={index} id={id} onEditClick={(index) => editFacetTrigger(index)} onDeactivateBtnClicked={(index) => deactiveFacetField(index)} />)}
											</ul>
										</SortableContext>
									</DndContext>
									<div style={{ width: '100%', border: '1px solid lightgrey', marginBottom: '1rem', marginTop: "1rem" }}></div>
									<h4 >Inactive</h4>
									<div style={{ marginTop: "10px" }}>
										{instantFacetInactiveFields.length > 0 ? (
											<>
												<ul style={{ display: 'flex', columnGap: '20px', rowGap: '10px', flexWrap: "wrap" }}>
													{instantFacetInactiveFields.map((field, index) => {
														return (
															<li key={index}>
																<div className="inactiveSortFIeldWrapper">
																	<span title={field.title}>{field.title}</span>
																	<button
																		onClick={(e) => {
																			e.preventDefault();
																			activatedFacetField(index);
																		}}
																	>{<FontAwesomeIcon
																		icon={faAdd}
																		size={"1x"}
																		color="#c9c9c9"
																	/>}</button>
																</div>
															</li>
														);
													})}
												</ul>
											</>
										) : (
											<span>No inactive facet fields</span>
										)}
									</div>
								</div>
							</Col>
						</Row>
						{/* <div className="form-group" style={{ marginTop: "2rem" }}>
							<label htmlFor="products-count">
								<h4>Edit Javascript</h4>
							</label>
							<div className="rightContainer">
								<div className="input-container" style={{ padding: '5px 15px', borderColor: 'rgb(60, 100, 244)', background: 'rgb(246, 247, 252)' }}>
									<div className="editButton">
										<button
											type="button"
											style={{ border: 'none', background: 'none', fontSize: '18px', display: 'flex', alignItems: 'center', gap: '5px', color: 'rgb(60, 100, 244)' }}
											onClick={openModal}
										>
											<FontAwesomeIcon icon={faPen} size={"1x"} color="rgb(60, 100, 244)" /> Edit
										</button>
									</div>
								</div>
								<div className="buttonContext">
									<p>
										Add or edit the JavaScript used for the Instantsearch widget to customize the display of the product grid/list content.
									</p>
								</div>
							</div>
							{modalOpen && (
								<div className="modal-overlay">
									<div className="modal-container">
										<div className="modal-header">
											<h4>Edit Javascript</h4>
											<button className="close-btn" onClick={closeModal}>X</button>
										</div>
										<div className="modal-body" style={{ maxHeight: '300px', overflowY: 'auto' }}>
											<textarea
												placeholder="Enter your custom JavaScript here..."
												style={{ width: '100%', height: '200px', padding: '10px', fontSize: '14px', borderRadius: '5px' }}
											></textarea>
										</div>
										<div className="modal-footer">
											<button className="footer-btn" onClick={closeModal}>Cancel</button>
											<button className="footer-btn" onClick={closeModal}>Save</button>
										</div>
									</div>
								</div>
							)}
						</div> */}
						<Row className='justify-content-end'>
							<Col xs={"auto"}>
								<Button type='button' color='primary' onClick={() => saveInstantSearchConfig()} disabled={saveLoader}>
									{saveLoader ?
										<Spinner size={"sm"} />
										:
										<>Save</>
									}
								</Button>
							</Col>
						</Row>
					</form>
				</div>
			</div>
			{renameModalFlag &&
				<Modal isOpen={renameModalFlag} toggle={closeSortTitleModal} centered size="lg">
					<ModalHeader style={{ backgroundColor: "transparent", color: "#333" }} toggle={closeSortTitleModal}>Rename a sort order</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup>
								<Label for="sortTitle">Title</Label>
								<Input
									id="sortTitle"
									type="text"
									value={currentSortField.title}
									onChange={(e) => handleSortTitle(e.target.value)}
									autoComplete="off"
								/>
								<small className="form-text text-muted">This name will be displayed on the search page</small>
							</FormGroup>
							<FormGroup>
								<Label for="sortAttribute">Based on the attribute</Label>
								<Input id="sortAttribute" type="text" value={currentSortField.attribute} disabled />
							</FormGroup>
							<FormGroup>
								<Input
									type="text"
									value={currentSortField.sort === "asc" ? "Ascending" : "Descending"}
									disabled
								/>
								<small className="form-text text-muted">Ascending means the results will be ranked from A &gt; Z for text-based attributes, or from low to high value for numbers. Descending is the other way around (Z &gt; A, high to low).</small>
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={closeSortTitleModal}>Cancel</Button>
						<Button color="primary" onClick={saveSortTitle}>Save</Button>
					</ModalFooter>
				</Modal>
			}
			{editModalFlag &&

				<Modal isOpen={editModalFlag} toggle={closeEditFacetModal} centered>
					<ModalHeader style={{backgroundColor: "transparent", color: "#333"}} toggle={closeEditFacetModal}>Edit a facet</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup>
								<Label for="facetTitle">Title</Label>
								<Input
									id="facetTitle"
									type="text"
									value={currentFacetField.title}
									onChange={handleFacetTitle}
									autoComplete="off"
								/>
								<small className="form-text text-muted">
									This name will be displayed on the search page
								</small>
							</FormGroup>
							<FormGroup>
								<Label for="facetType">Facet type</Label>
								<Input
									type="select"
									id="facetType"
									value={currentFacetField.facetType}
									onChange={facetTypeChange}
								>
									{currentFacetField.attribute === "price"
										? priceOptions.map((option, index) => (
											<option key={index} value={option.value}>
												{option.label}
											</option>
										))
										: otherOptions.map((option, index) => (
											<option key={index} value={option.value}>
												{option.label}
											</option>
										))}
								</Input>
							</FormGroup>
							<FormGroup
								check
								inline
								className="mb-4"
							>
								<Input type="checkbox" checked={currentFacetField.searchable} onChange={(e) => searchableChange(e.target.checked)} />
								<Label check>
									Searchable facet
								</Label>
							</FormGroup>
							<FormGroup>
								<div className="alert alert-info">
									This facet has been generated from the default attribute: <strong>{currentFacetField.attribute}</strong>
								</div>
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter>						
						<Button color="secondary" onClick={closeEditFacetModal}>Cancel</Button>
						<Button color="primary" onClick={saveFacetValue}>Save</Button>
					</ModalFooter>
				</Modal>

			}
		</React.Fragment>
	);
};

export default InstantSearch;
