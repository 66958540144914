
import React, { useContext, useEffect, useRef, useState } from 'react'
import MenuHeader from './MenuHeader'
import { ProductContext } from './../../../store'
import DetailSkeleton from '../../Skeleton/DetailSkeleton'
import { FormGroup, Input, Label } from 'reactstrap'
import { Form } from 'react-router-dom'

const GeneralSearch = ({ type, pageLoader }) => {
	const divRef = useRef(null),
		[height, setHeight] = useState("100vh"),
		{
			setSearchPhrase,
			searchPhrase,
			description,
			setDescription,
			exact,
			setExact,
			editSearchPhraseMerchandising,
			loadVisualEditor,
			defaultStoreView,
			websitePlatform,
			magentoStoreViews,
			setDefaultStoreView
		} = useContext(ProductContext)

	useEffect(() => {
		const calculateHeight = () => {
			if (divRef.current) {
				const topPosition = divRef.current.getBoundingClientRect().top
				const newHeight = `calc(100vh - ${topPosition}px)`
				setHeight(newHeight)
			}
		}
		// Calculate height initially
		calculateHeight()

		// Recalculate height on window resize
		window.addEventListener("resize", calculateHeight)
		return () => {
			window.removeEventListener("resize", calculateHeight)
		}
	}, [divRef, loadVisualEditor])

	return (
		<React.Fragment>
			<MenuHeader title={"General"} />
			<div className='sideBarMenuGridContainer' ref={divRef} style={{ height: height, position: "relative" }}>
				<div className='menuContentWrapper'>
					<div style={{ display: 'flex', flexDirection: "column", padding: "20px" }}  >
						{pageLoader ?
							<DetailSkeleton />
						:
							<div>
								{websitePlatform === "magento" && Object.keys(magentoStoreViews).length > 1 &&
									<FormGroup style={{marginBottom: "20px"}}>
										{type === "new" ?
											<>
												<Label for="storeViews">Store Views</Label>
												<Input
													type="select"
													value={defaultStoreView}
													name='storeView'
													onChange={(e) => setDefaultStoreView(e.target.value)}
												>
													{Object.keys(magentoStoreViews).map((key, index) => {
														return(
															<option value={key}>{magentoStoreViews[key]}</option>
														)
													})}
												</Input>
											</>
										: ( type === "edit" && editSearchPhraseMerchandising ) &&
											<>
												<Label className="font-weight-bold">Store Views</Label>
												<p>{magentoStoreViews[editSearchPhraseMerchandising.storeView]}</p>
											</>
										
										}
									</FormGroup>
								}
								<FormGroup style={{ marginBottom: "20px" }}>
									{type === "new" ? (
										<>
											<Label for="searchPhrase">Search Phrase (required)</Label>
											<Input
												id="searchPhrase"
												type="text"
												value={searchPhrase}
												placeholder="Enter query pattern"
												required
												onChange={(e) => setSearchPhrase(e.target.value)}
											/>
										</>
									) : (
										type === "edit" &&
										editSearchPhraseMerchandising && (
											<>
												<Label className="font-weight-bold">Search Pattern</Label>
												<p>{editSearchPhraseMerchandising.searchPhrase}</p>
											</>
										)
									)}
								</FormGroup>
								<FormGroup tag="fieldset">
									<Label>Match Method</Label>
									{type === "new" ? (
										<>
											<FormGroup check>
												<Input
													name="matchMethod"
													type="radio"
													checked={exact}
													onChange={() => setExact(true)}
												/>
												{' '}
												<Label check>Exact Match</Label>
											</FormGroup>
											<FormGroup check>
												<Input
													name="matchMethod"
													type="radio"
													checked={!exact}
													onChange={() => setExact(false)}
												/>
												{' '}
												<Label check>Broad Match</Label>
											</FormGroup>
										</>
									) : (type === "edit"  && editSearchPhraseMerchandising && (
										<FormGroup>
											<p>{editSearchPhraseMerchandising.exact ? "Exact Match" : "Broad Match"}</p>
										</FormGroup>
									))}
								</FormGroup>
								<FormGroup style={{ marginBottom: "20px" }}>
									<Label for="description">Description</Label>
									<Input
										id="description"
										type="textarea"
										placeholder="Description"
										rows={3}
										value={description}
										onChange={(e) => setDescription(e.target.value)}
									/>
								</FormGroup>
							</div>
						}
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}
export default GeneralSearch