const build	= "development";

let microURL = {
						ms1: "devbackend.",
						ms2: "devmagebe.",
						ms3: "micro3.",
						ms4: "micro4.",
						ms5: "micro5."
					},
mainURL = "conversionbox.io/",
serverHost	= "https://";
if( build === "live" ){
	microURL = {
					ms1: "backend.",
					ms2: "magebe.",
					ms3: "micro3.",
					ms4: "micro4.",
					ms5: "micro5."
				};
}else if( build === "local" ){
	microURL = {
		ms1: "localhost:8981",
		ms2: "localhost:8991",
		ms3: "micro3.",
		ms4: "micro4.",
		ms5: "micro5."
	}
	serverHost	= "http://"
	mainURL	= "/"
}

const serverURL = serverHost;
// export const mainUrl2 = (props) => { // const appURL2 = props// }

export const appURL = mainURL;

// export const previewURL = appURL === "roundclicks.com/" ? `https://app.${appURL}` : `https://alpha.${appURL}`;

export const ms1URL = serverURL + microURL.ms1 + appURL;
export const ms2URL = serverURL + microURL.ms2 + appURL;
export const ms3URL = serverURL + microURL.ms3 + appURL;
export const ms4URL = serverURL + microURL.ms4 + appURL;
export const ms5URL = serverURL + microURL.ms5 + appURL;