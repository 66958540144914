import React, { useEffect, useRef,useContext } from 'react';
import Header from './Header';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { SearchRoutes, userRoutes ,merchandiseRoutes} from '../Routes';
import { ProductContext } from '../store';
const Content = ({type}) => {
	const {
		editorPageFlag
		} = useContext(ProductContext);
	const mainContent	= useRef(null),
		location	= useLocation()

	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		if( mainContent.current ){
			mainContent.current.scrollTop = 0;
		}
	}, [location]);

	const getRoutes = () => {
		let returnRoutes	= null
		if( type === "search" ){
			returnRoutes = SearchRoutes.map((searchRoute, key) => {
				return (
					<React.Fragment key={key}>
						<Route
							exact
							path={searchRoute.path}
							element={searchRoute.component}
						/>
						{searchRoute.subMenu &&
							searchRoute.subMenu.map((subMenu, index) => {
								return (
									<Route
										exact
										path={subMenu.path}
										element={subMenu.component}
									/>
								)
							})
						}
						{key + 1 === SearchRoutes.length &&
							<Route path="*" element={<Navigate to="/search/dashboard" replace />} />
						}
					</React.Fragment>
				);
			})
		}else{
			returnRoutes = userRoutes.map((prop, key) => {
										return (
											<>
												<Route
													exact
													path={prop.path}
													element={prop.component}
													key={key}
												/>
												{key + 1 === userRoutes.length &&
													<Route path="*" element={<Navigate to="/user/dashboard" replace />} />
												}
											</>
										);
									})
		}
		return returnRoutes
	}
	return (
		<div className='contentMainContainer' ref={mainContent}>
			<Header />
			<div className={editorPageFlag?'pageContentWrapperNoPadding':'pageContentWrapper'}>
				<div className='pageContentInnerWrapper'>
					<Routes>
						{getRoutes()}						
					</Routes>
				</div>
			</div>
		</div>
	);
}

export default Content;