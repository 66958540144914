import {
	faAdd,
	faArrowLeft,
	faArrowRight,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronLeft,
	faChevronRight,
	faEdit,
	faEllipsisV,
	faLayerGroup,
	faMinus,
	faPen,
	faPlus,
	faSearch,
	faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useContext, useEffect, useState } from "react"
import Switch from "react-switch"
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core"
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { SortableItem } from "./../../../../components/Search/SortableItem"
import DetailSkeleton from "../../../Skeleton/DetailSkeleton"
import debounce from "lodash.debounce"
import { PostData } from "../../../../services/FetchData"
import { ProductContext } from "../../../../store"
import { toast } from "react-toastify"
import { Button, Card, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, Tooltip } from "reactstrap"
import { FacetItem } from "../../FacetItem"
import ReactPaginate from "react-paginate"

const Category = () => {
	const [loader, setLoader] = useState(true),
		[template, setTemplate] = useState("bigImage"),
		[categorySearchName, setCategorySearchName] = useState(''),
		[categoryStatusLoader, setCategoryStatusLoader] = useState(false),
		[categorySearchConfig, setCategorySearchConfig] = useState(null),
		[categorySortActiveFields, setCategorySortActiveFields] = useState([]),
		[categoryItemSearchConfig, setCategoryItemSearchConfig] = useState([]),
		[categorySortInactiveFields, setCategorySortInactiveFields] = useState([]),
		[categoryFacetActiveFields, setCategoryFacetActiveFields] = useState([]),
		[categoryFacetInactiveFields, setCategoryFacetInactiveFields] = useState([]),
		[currentPage, setCurrentPage] = useState(0),
		[pageSize, setPageSize] = useState(10),
		[categoryConfigSave, setCategoryConfigSave] = useState(false),
		[categoryCustomizeFlag, setCategoryCustomizeFlag] = useState(false),
		[fetchCategoryLoader, setFetchCategoryLoader]	= useState(false),
		[editModalFlag, setEditModalFlag] = useState(false),
		[editFacetFlag, setEditFacetFlag] = useState(false),
		[currentFacetField, setCurrentFacetField] = useState({}),
		[currentFacetIndex, setCurrentFacetIndex] = useState(null),
		[currentSortField, setCurrentSortField] = useState({}),
		[renameModalFlag, setRenameModalFlag] = useState(false),
		[renameCategoryItem, setRenameCategoryItem] = useState(false),
		[currentSortIndex, setCurrentSortIndex] = useState(null),
		[categoryItemCount, setCategoryItemCount] = useState(0),
		[categoryPageLoader, setCategoryPageLoader] = useState(true),
		[customizeCategoryId, setCustomizeCategoryId] = useState(null),
		[categoryItemLoader, setCategoryItemLoader] = useState(false),
		[currentCategoryItem, setCurrentCategoryItem] = useState(null),
		[categoryFacetActive, setCategoryFacetActive] = useState([]),
		[categoryFacetInactive, setCategoryFacetInactive] = useState([]),
		[categorySortActive, setCategorySortActive] = useState([]),
		[categorySortInactive, setCategorySortInactive] = useState([]),
		[categoryDetails, setCategoryDetails] = useState({}),
		[enableLoader, setEnableLoader] = useState(false),
		[disableLoader, setDisableLoader] = useState(false),
		[saveCategoryItem, setSaveCategoryItem] = useState(false),
		[tooltipOpen, setTooltipOpen]	= useState(false),
		[productCount, setProductCount] = useState(0),
		[paginationLoader, setPaginationLoader] = useState(true),
		[totalPage, setTotalPage] = useState(0),
		[categoryIndexFlag, setCategoryIndexFlag] = useState(true),
		[pageChangeFlag, setPageChangeFlag] = useState(false),
		{
			currentWebsiteId
		} = useContext(ProductContext)

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	)

	useEffect(() => {
		getCategorySearchConfig()
	}, [])

	useEffect(() => {
		if (pageChangeFlag) {
			getCategoryItems()
			setPageChangeFlag(false)
		}
	}, [pageChangeFlag])

	const debouncedSearch = useCallback(
		debounce(async (searchTerm) => {
			setPaginationLoader(true)
			await getCategoryItems(searchTerm, 0)
		}, 3000),
		[]
	)

	useEffect(() => {
		if (!categoryPageLoader) {
			debouncedSearch(categorySearchName)
		}
	}, [categorySearchName, debouncedSearch])

	useEffect(() => {
		if (customizeCategoryId !== null) {
			let currentCategoryItemLocal = categoryItemSearchConfig.filter((item) => item.categoryId === customizeCategoryId)
			// if (currentCategoryItemLocal && currentCategoryItemLocal.length > 0 && currentCategoryItemLocal[0].customization) {
				getCategoryItemFacetSort()
			// } else {
			// 	setCategoryItemLoader(false)
			// }
		}
	}, [customizeCategoryId])

	const getCategorySearchConfig = async () => {
		PostData("ms2", "api/v1/magento/getCategorySearchConfig", { websiteId: currentWebsiteId, page: currentPage + 1, pageSize, name: categorySearchName }).then((result) => {
			if (result !== "Invalid" && result !== "undefined") {
				if (result.status === "success") {
					setCategorySearchConfig(result.data.categorySearchConfig)
					setCategorySortActiveFields(result.data.categorySortActiveFields)
					setCategorySortInactiveFields(result.data.categorySortInactiveFields)
					setCategoryFacetActiveFields(result.data.categoryFacetActiveFields)
					setCategoryFacetInactiveFields(result.data.categoryFacetInactiveFields)
					setCategoryDetails(result.data.categoryDetails)
					setProductCount(result.data.categoryProductCount)
					setCategoryItemSearchConfig(result.data.categoryItemSearchConfig)
					setCategoryItemCount(result.data.categoryItemCount)
					let totalPage = Math.ceil(result.data.categoryItemCount / pageSize)
					setTotalPage(totalPage)
					if (result.data.searchResultDesign) {
						setTemplate(result.data.searchResultDesign.template)
					}
					setLoader(false)
					setPaginationLoader(false)
					setCategoryPageLoader(false)
				}
				setCategoryIndexFlag(false)
			}
		})


	}

	const getCategoryItems = async (searchTerm = null, pageValue = null) => {
		let name = searchTerm !== null ? searchTerm : categorySearchName,
			page = pageValue !== null ? pageValue + 1 : currentPage + 1
		setCategoryPageLoader(true)
		PostData("ms2", "api/v1/magento/getCategoryItems", { page, pageSize, name, websiteId: currentWebsiteId }).then((result) => {
			if (result !== "Invalid" && result !== "undefined") {
				if (result.status === "success") {
					setCategoryItemSearchConfig(result.data.categoryItemSearchConfig)
				}
				setPaginationLoader(false)
				setCategoryPageLoader(false)
				setCategoryItemCount(result.data.categoryItemCount)
				let totalPage = Math.ceil(result.data.categoryItemCount / pageSize)
				setTotalPage(totalPage)
			}
		})
	}

	const getCategoryItemFacetSort = async () => {
		PostData("ms2", "api/v1/magento/getCategoryItem", { categoryId: customizeCategoryId, websiteId: currentWebsiteId }).then((result) => {
			if (result !== "Invalid" && result !== "undefined") {
				if (result.status === "success") {
					setCurrentCategoryItem(result.data.categoryItem)
					setCategorySortActive(result.data.categorySortActive)
					setCategorySortInactive(result.data.categorySortInactive)
					setCategoryFacetActive(result.data.categoryFacetActive)
					setCategoryFacetInactive(result.data.categoryFacetInactive)
				}
				setCategoryItemLoader(false)
			}
		})
	}

	const saveCategorySearchConfig = async () => {
		setCategoryConfigSave(true)
		let data = {
			...categorySearchConfig,
			activeSort: categorySortActiveFields,
			inactiveSort: categorySortInactiveFields,
			activeFacet: categoryFacetActiveFields,
			inactiveFacet: categoryFacetInactiveFields,
			websiteId: currentWebsiteId
		}
		PostData("ms2", "api/v1/magento/saveCategorySearchConfig", data).then((result) => {
			if (result !== "Invalid" && result !== "undefined") {
				if (result.status === "success") {
					toast.success("Setting Saved Successfully")
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
				setCategoryConfigSave(false)
			}
		})
	}

	const changeCategorySearchStatus = async () => {
		setCategoryStatusLoader(true)
		PostData("ms2", "api/v1/magento/saveCategorySearchStatus", { status: categorySearchConfig ? categorySearchConfig.status : false, websiteId: currentWebsiteId }).then(async (result) => {
			if (result !== "Invalid" && result !== "undefined") {
				if (result.status === "success") {
					// showToast function
					if (categorySearchConfig && categorySearchConfig.status) {
						toast.success("Category page search has been removed to your store front")
					} else {
						toast.success("Category Search has been applied to your store front")
					}
					if (categorySearchConfig) {
						setCategorySearchConfig({
							...categorySearchConfig,
							status: !categorySearchConfig.status,
						})
					} else {
						await getCategorySearchConfig()
					}
				}
				setCategoryStatusLoader(false)
			}
		})
	}

	const handleCategorySearchConfig = (index, value) => {
		setCategorySearchConfig({
			...categorySearchConfig,
			[index]: value,
		})
	}

	const findIndexByKey = (array, key, value) => {
		return array.findIndex((item) => item[key] === value)
	}

	const handleSortDragEnd = (event) => {
		const { active, over } = event
		if (active.id.sortFieldId !== over.id.sortFieldId) {
			let sortFieldsLocal = [...categorySortActiveFields],
				oldIndex = findIndexByKey(
					sortFieldsLocal,
					"sortFieldId",
					active.id.sortFieldId
				),
				newIndex = findIndexByKey(
					sortFieldsLocal,
					"sortFieldId",
					over.id.sortFieldId
				)
			sortFieldsLocal = arrayMove(sortFieldsLocal, oldIndex, newIndex)
			setCategorySortActiveFields(sortFieldsLocal)
		}
	}

	const handleCategoryItemDragEnd = (event) => {
		const { active, over } = event
		if (active.id.sortFieldId !== over.id.sortFieldId) {
			let sortFieldsLocal = [...categorySortActive],
				oldIndex = findIndexByKey(sortFieldsLocal, "sortFieldId", active.id.sortFieldId),
				newIndex = findIndexByKey(sortFieldsLocal, "sortFieldId", over.id.sortFieldId)
			sortFieldsLocal = arrayMove(sortFieldsLocal, oldIndex, newIndex)
			setCategorySortActive(sortFieldsLocal)
		}
	}

	const handleFacetDragEnd = (event) => {
		const { active, over } = event
		if (active.id.facetFieldId !== over.id.facetFieldId) {
			let facetFieldsLocal = [...categoryFacetActiveFields],
				oldIndex = findIndexByKey(
					facetFieldsLocal,
					"facetFieldId",
					active.id.facetFieldId
				),
				newIndex = findIndexByKey(
					facetFieldsLocal,
					"facetFieldId",
					over.id.facetFieldId
				)
			facetFieldsLocal = arrayMove(facetFieldsLocal, oldIndex, newIndex)
			setCategoryFacetActiveFields(facetFieldsLocal)
		}
	}

	const handleCategoryFacetDragEnd = (event) => {
		const { active, over } = event
		if (active.id.facetFieldId !== over.id.facetFieldId) {
			let facetFieldsLocal = [...categoryFacetActive],
				oldIndex = findIndexByKey(facetFieldsLocal, "facetFieldId", active.id.facetFieldId),
				newIndex = findIndexByKey(facetFieldsLocal, "facetFieldId", over.id.facetFieldId)
			facetFieldsLocal = arrayMove(facetFieldsLocal, oldIndex, newIndex)
			setCategoryFacetActive(facetFieldsLocal)
		}
	}

	const closeSortTitleModal = () => {
		setCurrentSortField({})
		setCurrentSortIndex(null)
		setRenameModalFlag(false)
	}

	const closeSortItemTitleModal = () => {
		setCurrentSortField({})
		setCurrentSortIndex(null)
		setRenameCategoryItem(false)
	}

	const closeEditFacetModal = () => {
		setCurrentFacetField({})
		setCurrentFacetIndex(null)
		setEditModalFlag(false)
	}

	const closeItemEditFacetModal = () => {
		setCurrentFacetField({})
		setCurrentFacetIndex(null)
		setEditFacetFlag(false)
	}

	const saveSortTitle = () => {
		let activeSortLocal = [...categorySortActiveFields]
		activeSortLocal[currentSortIndex]["title"] = currentSortField.title
		setCategorySortActiveFields(activeSortLocal)
		setCurrentSortField({})
		setCurrentSortIndex(null)
		setRenameModalFlag(false)
	}

	const saveSortCategoryItemTitle = () => {
		let activeSortLocal = [...categorySortActive]
		activeSortLocal[currentSortIndex]["title"] = currentSortField.title
		setCategorySortActive(activeSortLocal)
		setCurrentSortField({})
		setCurrentSortIndex(null)
		setRenameCategoryItem(false)
	}

	const saveFacetValue = () => {
		let activeFacetLocal = [...categoryFacetActiveFields]
		activeFacetLocal[currentFacetIndex] = currentFacetField
		setCategoryFacetActiveFields(activeFacetLocal)
		setCurrentFacetField({})
		setCurrentFacetIndex(null)
		setEditModalFlag(false)
	}

	const saveCategoryItemFacetValue = () => {
		let activeFacetLocal = [...categoryFacetActive]
		activeFacetLocal[currentFacetIndex] = currentFacetField
		setCategoryFacetActive(activeFacetLocal)
		setCurrentFacetField({})
		setCurrentFacetIndex(null)
		setEditFacetFlag(false)
	}

	const renameModalTrigger = (index) => {
		setCurrentSortIndex(index)
		setCurrentSortField(categorySortActiveFields[index])
		setRenameModalFlag(true)
	}

	const renameModalCategoryItem = (index) => {
		setCurrentSortIndex(index)
		setCurrentSortField(categorySortActive[index])
		setRenameCategoryItem(true)
		//alertsManager.add(alert)
	}

	const editFacetTrigger = (index) => {
		setCurrentFacetIndex(index)
		setCurrentFacetField(categoryFacetActiveFields[index])
		setEditModalFlag(true)
	}

	const editCategoryItemFacetTrigger = (index) => {
		setCurrentFacetIndex(index)
		setCurrentFacetField(categoryFacetActive[index])
		setEditFacetFlag(true)
	}

	const handleSortTitle = (e) => {
		setCurrentSortField({
			...currentSortField,
			title: e.target.value,
		})
	}

	const handleFacetTitle = (e) => {
		setCurrentFacetField({
			...currentFacetField,
			title: e.target.value,
		})
	}

	const searchableChange = (value) => {
		setCurrentFacetField({
			...currentFacetField,
			searchable: value,
		})
	}

	const collapsibleChange = (value) => {
		setCurrentFacetField({
			...currentFacetField,
			collapsible: value
		})
	}

	const activeSortField = (index) => {
		let inactiveSortFieldLocal = [...categorySortInactiveFields],
			newActiveSortFields = inactiveSortFieldLocal[index],
			activeSortFieldLocal = [...categorySortActiveFields]
		inactiveSortFieldLocal.splice(index, 1)
		newActiveSortFields.order = activeSortFieldLocal.length - 1
		activeSortFieldLocal.push(newActiveSortFields)
		setCategorySortActiveFields(activeSortFieldLocal)
		setCategorySortInactiveFields(inactiveSortFieldLocal)
	}

	const activeCategoryItemSortField = (index) => {
		let inactiveSortFieldLocal = [...categorySortInactive],
			newActiveSortFields = inactiveSortFieldLocal[index],
			activeSortFieldLocal = [...categorySortActive]
		inactiveSortFieldLocal.splice(index, 1)
		newActiveSortFields.order = activeSortFieldLocal.length - 1
		activeSortFieldLocal.push(newActiveSortFields)
		setCategorySortActive(activeSortFieldLocal)
		setCategorySortInactive(inactiveSortFieldLocal)
	}

	const activatedFacetField = (index) => {
		let inactiveFacetFieldLocal = [...categoryFacetInactiveFields],
			newActiveFacetFields = inactiveFacetFieldLocal[index],
			activeFacetFieldLocal = [...categoryFacetActiveFields]
		inactiveFacetFieldLocal.splice(index, 1)
		newActiveFacetFields.order = activeFacetFieldLocal.length - 1
		activeFacetFieldLocal.push(newActiveFacetFields)
		setCategoryFacetActiveFields(activeFacetFieldLocal)
		setCategoryFacetInactiveFields(inactiveFacetFieldLocal)
	}

	const activatedCategoryItemFacet = (index) => {
		let inactiveFacetFieldLocal = [...categoryFacetInactive],
			newActiveFacetFields = inactiveFacetFieldLocal[index],
			activeFacetFieldLocal = [...categoryFacetActive]
		inactiveFacetFieldLocal.splice(index, 1)
		newActiveFacetFields.order = activeFacetFieldLocal.length - 1
		activeFacetFieldLocal.push(newActiveFacetFields)
		setCategoryFacetActive(activeFacetFieldLocal)
		setCategoryFacetInactive(inactiveFacetFieldLocal)
	}

	const deactiveSortField = (index) => {
		let inactiveSortFieldLocal = [...categorySortInactiveFields],
			activeSortFieldLocal = [...categorySortActiveFields],
			newInactiveSortFields = activeSortFieldLocal[index]
		activeSortFieldLocal.splice(index, 1)
		inactiveSortFieldLocal.push(newInactiveSortFields)
		setCategorySortActiveFields(activeSortFieldLocal)
		setCategorySortInactiveFields(inactiveSortFieldLocal)
	}

	const deactiveCategoryItemSortField = (index) => {
		let inactiveSortFieldLocal = [...categorySortInactive],
			activeSortFieldLocal = [...categorySortActive],
			newInactiveSortFields = activeSortFieldLocal[index]
		activeSortFieldLocal.splice(index, 1)
		inactiveSortFieldLocal.push(newInactiveSortFields)
		setCategorySortActive(activeSortFieldLocal)
		setCategorySortInactive(inactiveSortFieldLocal)
	}

	const deactiveFacetField = (index) => {
		let inactiveFacetFieldLocal = [...categoryFacetInactiveFields],
			activeFacetFieldLocal = [...categoryFacetActiveFields],
			newInactiveFacetFields = activeFacetFieldLocal[index]
		activeFacetFieldLocal.splice(index, 1)
		inactiveFacetFieldLocal.push(newInactiveFacetFields)
		setCategoryFacetActiveFields(activeFacetFieldLocal)
		setCategoryFacetInactiveFields(inactiveFacetFieldLocal)
	}

	const deactiveCategoryFacetField = (index) => {
		let inactiveFacetFieldLocal = [...categoryFacetInactive],
			activeFacetFieldLocal = [...categoryFacetActive],
			newInactiveFacetFields = activeFacetFieldLocal[index]
		activeFacetFieldLocal.splice(index, 1)
		inactiveFacetFieldLocal.push(newInactiveFacetFields)
		setCategoryFacetActive(activeFacetFieldLocal)
		setCategoryFacetInactive(inactiveFacetFieldLocal)
	}

	const facetTypeChange = (value) => {
		setCurrentFacetField({
			...currentFacetField,
			facetType: value,
		})
	}

	const enableCategoryCustomize = () => {
		setCategoryConfigSave(false)
		setCategoryCustomizeFlag((prevState) => !prevState)
	}

	const editCategoryItem = (categoryItemSearchId) => {
		let currentCategoryItemLocal = categoryItemSearchConfig.filter((item) => item.categoryItemSearchId === categoryItemSearchId)
		console.log(currentCategoryItemLocal)
		setCurrentCategoryItem(currentCategoryItemLocal.length > 0 ? currentCategoryItemLocal[0] : null)
		setCustomizeCategoryId(currentCategoryItemLocal.length > 0 ? currentCategoryItemLocal[0].categoryId : null)
		setCategoryItemLoader(true)
	}

	const goToCategoryItemPage = () => {
		setEnableLoader(false)
		setCustomizeCategoryId(null)
		setCategoryItemLoader(false)
		setCurrentCategoryItem(null)
		getCategoryItems()
	}

	const enableCategoryItem = async () => {
		setEnableLoader(true)
		PostData("ms2", "api/v1/magento/enableCategoryItem", { categoryId: customizeCategoryId, categoryItemSearchId: currentCategoryItem.categoryItemSearchId, websiteId: currentWebsiteId }).then(async (result) => {
			if (result !== "Invalid" && result !== "undefined") {
				if (result.status === "success") {
					await getCategoryItems()
					await getCategoryItemFacetSort()
					setCurrentCategoryItem({
						...currentCategoryItem,
						customization: true
					})
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
				setEnableLoader(false)
			}
		})
	}

	const saveCategoryItemFunc = async () => {
		setSaveCategoryItem(true)
		let data = {
			activeSort: categorySortActive,
			inactiveSort: categorySortInactive,
			activeFacet: categoryFacetActive,
			inactiveFacet: categoryFacetInactive,
			categoryId: customizeCategoryId,
			websiteId: currentWebsiteId
		}
		PostData("ms2", "api/v1/magento/saveCategoryItem", data).then((result) => {
			if (result !== "Invalid" && result !== "undefined") {
				if (result.status === "success") {
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
				setSaveCategoryItem(false)
			}
		})
	}

	const disableCategoryItem = async () => {
		setDisableLoader(true)
		let data = {
			categoryId: customizeCategoryId,
			categoryItemSearchId: currentCategoryItem.categoryItemSearchId,
			websiteId: currentWebsiteId
		}
		PostData("ms2", "api/v1/magento/disableCategoryItem", data).then(async (result) => {
			if (result !== "Invalid" && result !== "undefined") {
				if (result.status === "success") {
					await getCategoryItems()
					setCurrentCategoryItem({
						...currentCategoryItem,
						customization: false
					})
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
				setDisableLoader(false)
			}
		})
	}

	const fetchCategories = async() => {
		setFetchCategoryLoader(true)
		PostData("ms2", "api/v1/magento/fetchCategories", {websiteId: currentWebsiteId}).then(async (result) => {
			if (result !== "Invalid" && result !== "undefined") {
				if (result.status === "success") {
					await getCategoryItems()
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
			setFetchCategoryLoader(false)
		})
	}

	const priceOptions = [
		{ label: 'Slider', value: 'slider' },
	];

	const otherOptions = [
		{ label: 'Conjunctive', value: 'conjunctive' },
		{ label: 'Disjunctive', value: 'disjunctive' },
	];


	if (loader) {
		return <DetailSkeleton />
	}

	return (
		<React.Fragment>
			{customizeCategoryId === null ?
				<React.Fragment>
					<div className="search-options-card">
						<div className="card-header">
							<div className="title-text">
								<h3>Enable ConversionBox Search on Category pages</h3>
								<span>Enabling ConversionBox Search on Category pages will enable InstantSearch and faceting on your categories.</span>
							</div>
							<div className="switch-container">
								<span>{categorySearchConfig.status ? "Enabled" : "Disabled"}</span>
								{categoryStatusLoader ?
									<Spinner />
									:
									<Switch
										checked={categorySearchConfig.status}
										onChange={changeCategorySearchStatus}
										onColor={"#9eb3fc"}
										onHandleColor={"#3c64f4"}
										handleDiameter={25}
										uncheckedIcon={false}
										checkedIcon={false}
										boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
										activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
										height={15}
										width={40}
										className="react-switch"
										id="material-switch"
									/>
								}
							</div>
						</div>
						{categorySearchConfig.status ?
							<div className="card-content">
								<form>
									<Row className="mb-4 align-items-center">
										<Col xs={12} md={4}>
											<label htmlFor="products-count">
												<h4>No Of Products Shown</h4>
											</label>
										</Col>
										<Col xs={12} md={6}>
											<div className="input-container">
												<Button type="button" className="btn-minus" onClick={() => handleCategorySearchConfig("noOfProducts", categorySearchConfig.noOfProducts - 1)}>
													<FontAwesomeIcon icon={faMinus} />
												</Button>
												<Input type="number" id="products-count" value={categorySearchConfig.noOfProducts} onChange={(e) => { if (!isNaN(parseInt(e.target.value, 10))) { handleCategorySearchConfig("noOfProducts", e.target.value) } }} />
												<Button type="button" className="btn-plus" onClick={() => handleCategorySearchConfig("noOfProducts", categorySearchConfig.noOfProducts + 1)}>
													<FontAwesomeIcon icon={faPlus} />
												</Button>
											</div>
											<div className="buttonContext">
												<p className='mb-0'>Number of products to show in search. Defaults to 12.</p>
											</div>
										</Col>
									</Row>
									<Row className="mb-4 align-items-center">
										<Col xs={12} md={4}>
											<label htmlFor="products-count">
												<h4>Show Out Of Stock Products</h4>
											</label>
										</Col>
										<Col xs={12} md={6}>
											<div className="checkboxContainer">
												<Input type="checkbox" className="checkboxContainerInput" checked={categorySearchConfig.outOfStock} onChange={() => handleCategorySearchConfig("outOfStock", !categorySearchConfig.outOfStock)} />
											</div>
											<div className="buttonContext">
												<p className='mb-0'>Products marked as not in stock will show up in search results.</p>
											</div>
										</Col>
									</Row>
									<Row className="mb-4 align-items-center">
										<Col xs={12} md={4}>
											<label htmlFor="products-count">
												<h4>Show Price</h4>
											</label>
										</Col>
										<Col xs={12} md={6}>
											<div className="checkboxContainer">
												<Input type="checkbox" className="checkboxContainerInput" checked={categorySearchConfig.showPrice} onChange={() => handleCategorySearchConfig("showPrice", !categorySearchConfig.showPrice)} />
											</div>
										</Col>
									</Row>
									<Row className="mb-4 align-items-center">
										<Col xs={12} md={4}>
											<label htmlFor="products-count">
												<h4>Show SKU</h4>
											</label>
										</Col>
										<Col xs={12} md={6}>
											<div className="checkboxContainer">
												<Input type="checkbox" className="checkboxContainerInput" checked={categorySearchConfig.showSKU} onChange={() => handleCategorySearchConfig("showSKU", !categorySearchConfig.showSKU)} />
											</div>
										</Col>
									</Row>
									<Row className="mb-4 align-items-center">
										<Col xs={12} md={4}>
											<label htmlFor="products-count">
												<h4>Maximum Title Lines</h4>
											</label>
										</Col>
										<Col xs={12} md={6}>
											<div className="input-container">
												<Button type="button" className="btn-minus" onClick={() => handleCategorySearchConfig("titleMaxLines", categorySearchConfig.titleMaxLines - 1)}>
													<FontAwesomeIcon icon={faMinus} />
												</Button>
												<Input type="number" id="products-count" value={categorySearchConfig.titleMaxLines} onChange={(e) => { if (!isNaN(parseInt(e.target.value, 10))) { handleCategorySearchConfig("titleMaxLines", e.target.value) } }} />
												<Button type="button" className="btn-plus" onClick={() => handleCategorySearchConfig("titleMaxLines", categorySearchConfig.titleMaxLines + 1)}>
													<FontAwesomeIcon icon={faPlus} />
												</Button>
											</div>
										</Col>
									</Row>
									<Row className="mb-4 align-items-center">
										<Col xs={12} md={4}>
											<label htmlFor="products-count">
												<h4>Show Product Description</h4>
											</label>
										</Col>
										<Col xs={12} md={6}>
											<div className="checkboxContainer">
												<Input type="checkbox" className="checkboxContainerInput" checked={categorySearchConfig.showDescription} onChange={() => handleCategorySearchConfig("showDescription", !categorySearchConfig.showDescription)} />
											</div>
										</Col>
									</Row>
									{categorySearchConfig.showDescription &&
										<Row className="mb-4 align-items-center">
											<Col xs={12} md={4}>
												<label htmlFor="products-count">
													<h4>Maximum Description Lines</h4>
												</label>
											</Col>
											<Col xs={12} md={6}>
												<div className="input-container">
													<Button type="button" className="btn-minus" onClick={() => handleCategorySearchConfig("descriptionMaxLines", categorySearchConfig.descriptionMaxLines - 1)}>
														<FontAwesomeIcon icon={faMinus} />
													</Button>
													<Input type="number" id="products-count" value={categorySearchConfig.descriptionMaxLines} onChange={(e) => { if (!isNaN(parseInt(e.target.value, 10))) { handleCategorySearchConfig("descriptionMaxLines", e.target.value) } }} />
													<Button type="button" className="btn-plus" onClick={() => handleCategorySearchConfig("descriptionMaxLines", categorySearchConfig.descriptionMaxLines + 1)}>
														<FontAwesomeIcon icon={faPlus} />
													</Button>
												</div>
											</Col>
										</Row>
									}
									<Row className="mb-4 align-items-center">
										<Col xs={12} md={4}>
											<label htmlFor="products-count">
												<h4>Flip Image On Hover</h4>
											</label>
										</Col>
										<Col xs={12} md={6}>
											<div className="checkboxContainer">
												<Input type="checkbox" className="checkboxContainerInput" checked={categorySearchConfig.flipImageHover} onChange={() => handleCategorySearchConfig("flipImageHover", !categorySearchConfig.flipImageHover)} />
											</div>
										</Col>
									</Row>
									<Row className="mb-4 align-items-center">
										<Col xs={12} md={4}>
											<label htmlFor="products-count">
												<h4>Enable Add to Cart</h4>
											</label>
										</Col>
										<Col xs={12} md={6}>
											<div className="checkboxContainer">
												<Input type="checkbox" className="checkboxContainerInput" checked={categorySearchConfig.enableAddToCart} onChange={() => handleCategorySearchConfig("enableAddToCart", !categorySearchConfig.enableAddToCart)} />
											</div>
										</Col>
									</Row>
									<Row className="mb-4">
										<Col xs={12} md={4}>
											<label htmlFor="products-count">
												<h4>Sort orders</h4>
											</label>
										</Col>
										<Col xs={12} md={8}>
											<div style={{ display: "flex", flexDirection: 'column' }}>
												<p >
													Enable and configure the different product sorting options.
												</p>
												<p>
													In order to deliver our speed, our ranking is done at indexing
													time, which means that for each active sort order we create a
													new index which contains the same amount of records as the
													main one.
												</p>
												<h4 style={{ marginBottom: '10px' }} >Active</h4>
												<p >
													Use drag and drop to customise the order.
												</p>
												<p >
													Relevance (default)
												</p>
												<DndContext
													sensors={sensors}
													collisionDetection={closestCenter}
													onDragEnd={handleSortDragEnd}
												>
													<SortableContext
														items={categorySortActiveFields}
														strategy={verticalListSortingStrategy}
													>
														<ul className="cbSortList">
															{categorySortActiveFields.map((id, index) => <SortableItem key={index} index={index} id={id} onRenameClick={(index) => renameModalTrigger(index)} onDeactivateBtnClicked={(index) => deactiveSortField(index)} />)}
														</ul>
													</SortableContext>
												</DndContext>
												<div style={{ width: '100%', border: '1px solid lightgrey', marginBottom: '1rem', marginTop: "1rem" }}></div>
												<h4 >Inactive</h4>
												<div style={{ marginTop: "10px" }}>
													{categorySortInactiveFields.length > 0 ? (
														<>
															<ul style={{ display: 'flex', columnGap: '20px', rowGap: '10px', flexWrap: "wrap" }}>
																{categorySortInactiveFields.map((field, index) => {
																	return (
																		<li key={index}>
																			<div className="inactiveSortFIeldWrapper">
																				<span title={field.title}>{field.title}</span>
																				<button

																					onClick={(e) => {
																						e.preventDefault();
																						activeSortField(index);
																					}}
																				>{<FontAwesomeIcon
																					icon={faAdd}
																					size={"1x"}
																					color="#c9c9c9"
																				/>}</button>
																			</div>
																		</li>
																	);
																})}
															</ul>
														</>
													) : (
														<span>No inactive sort fields</span>
													)}
												</div>
											</div>
										</Col>
									</Row>
									<Row className="mb-4">
										<Col xs={12} md={4}>
											<label htmlFor="products-count">
												<h4>Facets</h4>
											</label>
										</Col>
										<Col xs={12} md={8}>
											<div style={{ display: "flex", flexDirection: 'column' }}>
												<p>Allow customers to explore the catalog by applying multiple filters.</p>
												<h4 style={{ marginBottom: '10px' }} >Active</h4>
												<p>Use drag and drop to customise the order.</p>
												<DndContext
													sensors={sensors}
													collisionDetection={closestCenter}
													onDragEnd={handleFacetDragEnd}
												>
													<SortableContext
														items={categorySortActiveFields}
														strategy={verticalListSortingStrategy}
													>
														<ul className="cbSortList">
															{categoryFacetActiveFields.map((id, index) => <FacetItem key={index} index={index} id={id} onEditClick={(index) => editFacetTrigger(index)} onDeactivateBtnClicked={(index) => deactiveFacetField(index)} />)}
														</ul>
													</SortableContext>
												</DndContext>
												<div style={{ width: '100%', border: '1px solid lightgrey', marginBottom: '1rem', marginTop: "1rem" }}></div>
												<h4 >Inactive</h4>
												<div style={{ marginTop: "10px" }}>
													{categoryFacetInactiveFields.length > 0 ? (
														<>
															<ul style={{ display: 'flex', columnGap: '20px', rowGap: '10px', flexWrap: "wrap" }}>
																{categoryFacetInactiveFields.map((field, index) => {
																	return (
																		<li key={index}>
																			<div className="inactiveSortFIeldWrapper">
																				<span title={field.title}>{field.title}</span>
																				<button
																					onClick={(e) => {
																						e.preventDefault();
																						activatedFacetField(index);
																					}}
																				>{<FontAwesomeIcon
																					icon={faAdd}
																					size={"1x"}
																					color="#c9c9c9"
																				/>}</button>
																			</div>
																		</li>
																	);
																})}
															</ul>
														</>
													) : (
														<span>No inactive facet fields</span>
													)}
												</div>
											</div>
										</Col>
									</Row>
									<Row className='justify-content-end'>
										<Col xs={"auto"}>
											<Button type='button' color='primary' onClick={() => saveCategorySearchConfig()} disabled={categoryConfigSave}>
												{categoryConfigSave ?
													<Spinner size={"sm"} />
													:
													<>Save</>
												}
											</Button>
										</Col>
									</Row>
								</form>
							</div>
							:
							<div className="card-content">
								<h3>What happens next?</h3>
								<p className="mt-3">Your existing Categories in Magento will stay the same, but their display will be managed by ConversionBox. Enabling this feature will offer you more control over layout and additional settings.</p>
								<p>We will need to perform a full reindex of your products and categories to incorporate these changes.</p>
							</div>
						}
					</div>
					{(categorySearchConfig && categorySearchConfig.status) &&
						<div style={{ margin: "20px" }}>
							<div className="mt-5 mb-5">
								<hr />
							</div>
							<div className="mt-2 mb-2">
								<div className="d-flex gap-2">
									<h2>Your Categories ({categoryItemCount})</h2>
									<div>
										<Button 
											id="fetchCategoriesTooltip"
											color="link" 
											disabled={fetchCategoryLoader} 
											onClick={() => fetchCategories()}
											style={{padding: 0}}
										>
											<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
												<polyline points="23 4 23 10 17 10"></polyline>
												<polyline points="1 20 1 14 7 14"></polyline>
												<path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
											</svg>
										</Button>
										<Tooltip
											placement="right" 
											isOpen={tooltipOpen} 
											target="fetchCategoriesTooltip" 
											toggle={() => setTooltipOpen(!tooltipOpen)}
										>
											Fetch the latest categories from Mangeto
										</Tooltip>
									</div>
								</div>
							</div>
							<div className="search-options-card">
								<div style={{ padding: "20px", backgroundColor: "#fff", boxShadow: "rgba(49, 52, 64, 0.2) 0px 1px 6px", borderRadius: "10px" }}>
									<div className="searchInputWrapper">
										<InputGroup>
											<InputGroupText className="searchIconInput">
												<FontAwesomeIcon icon={faSearch} />
											</InputGroupText>
											<Input
												type="text"
												placeholder="Search Category..."
												value={categorySearchName}
												onChange={(e) => setCategorySearchName(e.target.value)}
											/>
										</InputGroup>
									</div>
									<div className="cbCategoryTable">
										{categoryPageLoader ?
											<DetailSkeleton />
											:
											<>
												<Table responsive striped>
													<thead>
														<tr>
															<th>Category</th>
															<th style={{ textAlign: "right" }}>Customization</th>
														</tr>
													</thead>
													<tbody>
														{categoryItemSearchConfig.length > 0 ?
															categoryItemSearchConfig.map((category, index) => {
																return (
																	<tr key={index}>
																		<td>{category.categoryName}</td>
																		<td style={{ textAlign: "right" }}>
																			<Button style={{ padding: 0 }} color="link" onClick={() => editCategoryItem(category.categoryItemSearchId)}>Customize</Button>
																		</td>
																	</tr>
																)
															})
															:
															<tr>
																<td colSpan={2}>No Record Found</td>
															</tr>
														}
													</tbody>
												</Table>
											</>
										}
										{(totalPage > 1 && !paginationLoader) &&
											<div className='d-flex justify-content-center mt-5'>
												<ReactPaginate
													nextLabel=">"
													onPageChange={(event) => { setCurrentPage(event.selected); setPageChangeFlag(true) }}
													forcePage={currentPage}
													pageRangeDisplayed={3}
													marginPagesDisplayed={2}
													pageCount={totalPage}
													previousLabel="<"
													pageClassName="page-item"
													pageLinkClassName="page-link"
													previousClassName="page-item"
													previousLinkClassName="page-link"
													nextClassName="page-item"
													nextLinkClassName="page-link"
													breakLabel="..."
													breakClassName="page-item"
													breakLinkClassName="page-link"
													containerClassName="pagination"
													activeClassName="active"
													renderOnZeroPageCount={null}
												/>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					}
				</React.Fragment>
				:
				<>
					{categoryItemLoader ?
						<DetailSkeleton />
						:
						<div>
							<div className="mb-2">
								<Button
									disabled={saveCategoryItem || disableLoader}
									color="link"
									onClick={() => goToCategoryItemPage()}
									style={{ textDecoration: "none", padding: 0, marginBottom: "20px" }}
								>
									<FontAwesomeIcon icon={faChevronLeft} size="11" className="me-2" /> Back
								</Button>
							</div>
							<div className="d-flex justify-content-between align-items-center">
								<div>
									<h3 className="h5">{currentCategoryItem.categoryName}</h3>
								</div>
								<div>
									<small>Category</small>
								</div>
							</div>
							<div className="mt-2 mb-5">
								<hr />
							</div>
							{currentCategoryItem.customization ?
								<>
									<Card className="p-4 bg-light">
										<div className="searchOptionFormLayoutWrapper">
											<Form>
												<Row className="mb-4">
													<Col xs={12} md={4}>
														<label htmlFor="products-count">
															<h4>Sort orders</h4>
														</label>
													</Col>
													<Col xs={12} md={8}>
														<div style={{ display: "flex", flexDirection: 'column' }}>
															<p >
																Enable and configure the different product sorting options.
															</p>
															<p>
																In order to deliver our speed, our ranking is done at indexing
																time, which means that for each active sort order we create a
																new index which contains the same amount of records as the
																main one.
															</p>
															<h4 style={{ marginBottom: '10px' }} >Active</h4>
															<p >
																Use drag and drop to customise the order.
															</p>
															<p >
																Relevance (default)
															</p>
															<DndContext
																sensors={sensors}
																collisionDetection={closestCenter}
																onDragEnd={handleSortDragEnd}
															>
																<SortableContext
																	items={categorySortActive}
																	strategy={verticalListSortingStrategy}
																>
																	<ul className="cbSortList">
																		{categorySortActive.map((id, index) => <SortableItem key={index} index={index} id={id} onRenameClick={(index) => renameModalCategoryItem(index)} onDeactivateBtnClicked={(index) => deactiveCategoryItemSortField(index)} />)}
																	</ul>
																</SortableContext>
															</DndContext>
															<div style={{ width: '100%', border: '1px solid lightgrey', marginBottom: '1rem', marginTop: "1rem" }}></div>
															<h4 >Inactive</h4>
															<div style={{ marginTop: "10px" }}>
																{categorySortInactive.length > 0 ? (
																	<>
																		<ul style={{ display: 'flex', columnGap: '20px', rowGap: '10px', flexWrap: "wrap" }}>
																			{categorySortInactive.map((field, index) => {
																				return (
																					<li key={index}>
																						<div className="inactiveSortFIeldWrapper">
																							<span title={field.title}>{field.title}</span>
																							<button

																								onClick={(e) => {
																									e.preventDefault();
																									activeCategoryItemSortField(index);
																								}}
																							>{<FontAwesomeIcon
																								icon={faAdd}
																								size={"1x"}
																								color="#c9c9c9"
																							/>}</button>
																						</div>
																					</li>
																				);
																			})}
																		</ul>
																	</>
																) : (
																	<span>No inactive sort fields</span>
																)}
															</div>
														</div>
													</Col>
												</Row>
												<Row className="mb-4 mt-5">
													<Col xs={12} md={4}>
														<label htmlFor="products-count">
															<h4>Facets</h4>
														</label>
													</Col>
													<Col xs={12} md={8}>
														<div style={{ display: "flex", flexDirection: 'column' }}>
															<p>Allow customers to explore the catalog by applying multiple filters.</p>
															<h4 style={{ marginBottom: '10px' }} >Active</h4>
															<p>Use drag and drop to customise the order.</p>
															<DndContext
																sensors={sensors}
																collisionDetection={closestCenter}
																onDragEnd={handleFacetDragEnd}
															>
																<SortableContext
																	items={categoryFacetActive}
																	strategy={verticalListSortingStrategy}
																>
																	<ul className="cbSortList">
																		{categoryFacetActive.map((id, index) => <FacetItem key={index} index={index} id={id} onEditClick={(index) => editCategoryItemFacetTrigger(index)} onDeactivateBtnClicked={(index) => deactiveCategoryFacetField(index)} />)}
																	</ul>
																</SortableContext>
															</DndContext>
															<div style={{ width: '100%', border: '1px solid lightgrey', marginBottom: '1rem', marginTop: "1rem" }}></div>
															<h4 >Inactive</h4>
															<div style={{ marginTop: "10px" }}>
																{categoryFacetInactive.length > 0 ? (
																	<>
																		<ul style={{ display: 'flex', columnGap: '20px', rowGap: '10px', flexWrap: "wrap" }}>
																			{categoryFacetInactive.map((field, index) => {
																				return (
																					<li key={index}>
																						<div className="inactiveSortFIeldWrapper">
																							<span title={field.title}>{field.title}</span>
																							<button
																								onClick={(e) => {
																									e.preventDefault();
																									activatedCategoryItemFacet(index);
																								}}
																							>{<FontAwesomeIcon
																								icon={faAdd}
																								size={"1x"}
																								color="#c9c9c9"
																							/>}</button>
																						</div>
																					</li>
																				);
																			})}
																		</ul>
																	</>
																) : (
																	<span>No inactive facet fields</span>
																)}
															</div>
														</div>
													</Col>
												</Row>
												<Row className='justify-content-end'>
													<Col xs={"auto"}>
														<Button type='button' color='primary' onClick={() => saveCategoryItemFunc()} disabled={saveCategoryItem}>
															{saveCategoryItem ?
																<Spinner size={"sm"} />
																:
																<>Save</>
															}
														</Button>
													</Col>
												</Row>
											</Form>
										</div>
									</Card>
									<div className="mt-5 mb-4">
										<Card body>
											<h5 className="fw-bold">Customise the behaviour of this category</h5>
											<p className="mb-3">
												If you disable customizations on this category page, it will follow the general category page settings without exceptions.
											</p>
											<div className="text-end">
												<Button 
													color="danger" 
													disabled={disableLoader || saveCategoryItem} 
													onClick={() => disableCategoryItem()}
												>
													{disableLoader ? "Disabling..." : "Disable"}
												</Button>
											</div>
										</Card>
									</div>
								</>
								:
								<>
									<Card className="p-4 rounded" style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}>
										<div>
											<div>
												<div className="mb-1">
													<h2 className="h5 fw-medium">Customise the behaviour of this category</h2>
												</div>
												<div className="mb-4">
													<div className="toggleContainer">
														<div className="d-flex justify-content-between">
															<p>
																If you allow customizations on this category page, you can configure it to behave
																differently than others, i.e., create an exception out of general category page settings.
															</p>
														</div>
													</div>
												</div>
												<div className="d-flex justify-content-end">
													<Button
														color="primary"
														disabled={enableLoader}
														onClick={() => enableCategoryItem()}
													>
														{enableLoader ? "Loading..." : "Enable"}
													</Button>
												</div>
											</div>
										</div>
									</Card>
									<div style={{ display: "flex", alignItems: "center", justifyContent: "center", opacity: 0.5, marginTop: "40px", maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}>
										<Card className="p-4 bg-light">
											<div className="searchOptionFormLayoutWrapper">
												<Form>
													<Row>
														<Col md={8}>
															<div>
																<h6 className="fw-medium">Sort orders</h6>
																<p className="text-muted">Enable and configure the different product sorting options.</p>
																<p className="text-muted">In order to deliver our speed, our ranking is done at indexing time, which means that for each active sort order we create a new index which contains the same amount of records as the main one.</p>
															</div>
														</Col>
														<Col md={4} className="d-flex align-items-center justify-content-center">
															<p className="text-muted">Allow customisation first</p>
														</Col>
													</Row>
												</Form>
											</div>
										</Card>
									</div>
									<div style={{ display: "flex", alignItems: "center", justifyContent: "center", opacity: 0.5, marginTop: "40px", maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}>
										<Card className="p-4 bg-light">
											<div className="searchOptionFormLayoutWrapper">
												<Form>
													<Row>
														<Col md={8}>
															<div>
																<h6 className="fw-medium">Facets</h6>
																<p className="text-muted">Allow customers to explore thecatalog by applying multiple filters.</p>
															</div>
														</Col>
														<Col md={4} className="d-flex align-items-center justify-content-center">
															<p className="text-muted">Allow customisation first</p>
														</Col>
													</Row>
												</Form>
											</div>
										</Card>
									</div>
								</>
							}
						</div>
					}
				</>
			}
			{renameModalFlag &&
				<Modal isOpen={renameModalFlag} toggle={closeSortTitleModal} centered size="lg">
					<ModalHeader style={{ backgroundColor: "transparent", color: "#333" }} toggle={closeSortTitleModal}>Rename a sort order</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup>
								<Label for="sortTitle">Title</Label>
								<Input
									id="sortTitle"
									type="text"
									value={currentSortField.title}
									onChange={(e) => handleSortTitle(e)}
									autoComplete="off"
								/>
								<small className="form-text text-muted">This name will be displayed on the search page</small>
							</FormGroup>
							<FormGroup>
								<Label for="sortAttribute">Based on the attribute</Label>
								<Input id="sortAttribute" type="text" value={currentSortField.attribute} disabled />
							</FormGroup>
							<FormGroup>
								<Input
									type="text"
									value={currentSortField.sort === "asc" ? "Ascending" : "Descending"}
									disabled
								/>
								<small className="form-text text-muted">Ascending means the results will be ranked from A &gt; Z for text-based attributes, or from low to high value for numbers. Descending is the other way around (Z &gt; A, high to low).</small>
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={closeSortTitleModal}>Cancel</Button>
						<Button color="primary" onClick={saveSortTitle}>Save</Button>
					</ModalFooter>
				</Modal>
			}
			{editModalFlag &&
				<Modal isOpen={editModalFlag} toggle={closeEditFacetModal} centered>
					<ModalHeader style={{ backgroundColor: "transparent", color: "#333" }} toggle={closeEditFacetModal}>Edit a facet</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup>
								<Label for="facetTitle">Title</Label>
								<Input
									id="facetTitle"
									type="text"
									value={currentFacetField.title}
									onChange={handleFacetTitle}
									autoComplete="off"
								/>
								<small className="form-text text-muted">
									This name will be displayed on the search page
								</small>
							</FormGroup>
							<FormGroup>
								<Label for="facetType">Facet type</Label>
								<Input
									type="select"
									id="facetType"
									value={currentFacetField.facetType}
									onChange={(e) => facetTypeChange(e.target.value)}
								>
									{currentFacetField.attribute === "price"
										? priceOptions.map((option, index) => (
											<option key={index} value={option.value}>
												{option.label}
											</option>
										))
										: otherOptions.map((option, index) => (
											<option key={index} value={option.value}>
												{option.label}
											</option>
										))}
								</Input>
							</FormGroup>
							<FormGroup
								check
								inline
								className="mb-4"
							>
								<Input type="checkbox" checked={currentFacetField.searchable} onChange={(e) => searchableChange(e.target.checked)} />
								<Label check>
									Searchable facet
								</Label>
							</FormGroup>
							<FormGroup>
								<div className="alert alert-info">
									This facet has been generated from the default attribute: <strong>{currentFacetField.attribute}</strong>
								</div>
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={closeEditFacetModal}>Cancel</Button>
						<Button color="primary" onClick={saveFacetValue}>Save</Button>
					</ModalFooter>
				</Modal>

			}
			{renameCategoryItem &&
				<Modal isOpen={renameCategoryItem} toggle={closeSortItemTitleModal} centered size="lg">
					<ModalHeader style={{ backgroundColor: "transparent", color: "#333" }} toggle={closeSortItemTitleModal}>Rename a sort order</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup>
								<Label for="sortTitle">Title</Label>
								<Input
									id="sortTitle"
									type="text"
									value={currentSortField.title}
									onChange={(e) => handleSortTitle(e)}
									autoComplete="off"
								/>
								<small className="form-text text-muted">This name will be displayed on the search page</small>
							</FormGroup>
							<FormGroup>
								<Label for="sortAttribute">Based on the attribute</Label>
								<Input id="sortAttribute" type="text" value={currentSortField.attribute} disabled />
							</FormGroup>
							<FormGroup>
								<Input
									type="text"
									value={currentSortField.sort === "asc" ? "Ascending" : "Descending"}
									disabled
								/>
								<small className="form-text text-muted">Ascending means the results will be ranked from A &gt; Z for text-based attributes, or from low to high value for numbers. Descending is the other way around (Z &gt; A, high to low).</small>
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={closeSortItemTitleModal}>Cancel</Button>
						<Button color="primary" onClick={saveSortCategoryItemTitle}>Save</Button>
					</ModalFooter>
				</Modal>
			}
			{editFacetFlag &&
				<Modal isOpen={editFacetFlag} toggle={closeItemEditFacetModal} centered>
					<ModalHeader style={{ backgroundColor: "transparent", color: "#333" }} toggle={closeItemEditFacetModal}>Edit a facet</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup>
								<Label for="facetTitle">Title</Label>
								<Input
									id="facetTitle"
									type="text"
									value={currentFacetField.title}
									onChange={handleFacetTitle}
									autoComplete="off"
								/>
								<small className="form-text text-muted">
									This name will be displayed on the search page
								</small>
							</FormGroup>
							<FormGroup>
								<Label for="facetType">Facet type</Label>
								<Input
									type="select"
									id="facetType"
									value={currentFacetField.facetType}
									onChange={(e) => facetTypeChange(e.target.value)}
								>
									{currentFacetField.attribute === "price"
										? priceOptions.map((option, index) => (
											<option key={index} value={option.value}>
												{option.label}
											</option>
										))
										: otherOptions.map((option, index) => (
											<option key={index} value={option.value}>
												{option.label}
											</option>
										))}
								</Input>
							</FormGroup>
							<FormGroup
								check
								inline
								className="mb-4"
							>
								<Input type="checkbox" checked={currentFacetField.searchable} onChange={(e) => searchableChange(e.target.checked)} />
								<Label check>
									Searchable facet
								</Label>
							</FormGroup>
							<FormGroup>
								<div className="alert alert-info">
									This facet has been generated from the default attribute: <strong>{currentFacetField.attribute}</strong>
								</div>
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={closeItemEditFacetModal}>Cancel</Button>
						<Button color="primary" onClick={saveCategoryItemFacetValue}>Save</Button>
					</ModalFooter>
				</Modal>

			}
		</React.Fragment>
	)
}

export default Category