import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { Navigate, useLocation } from "react-router-dom";
import SearchSidebar from "../components/Search/SearchSidebar";
import Content from "../components/Content";
import { ProductContext } from "../store";
import Cookies from 'js-cookie';
import { PostData } from "../services/FetchData";
import Notification from "../components/Notification";

const Search = () => {
	const [isLogged, setIsLogged] = useState(true),
		[currentUrl, setCurrentUrl] = useState(null),
		mainContent = React.useRef(null),
		location = useLocation(),
		{
			setUserData,
			setUserLoader,
			setWebsiteLoader,
			setWebsiteData,
			setCurrentWebsiteId,
			editorPageFlag
		} = useContext(ProductContext);

	useEffect(() => {
		document.documentElement.scrollTop = 0
		document.scrollingElement.scrollTop = 0
		if (mainContent.current) {
			mainContent.current.scrollTop = 0
		}
		setCurrentUrl(location.pathname)
	}, [location]);

	useEffect(() => {
		const Token = Cookies.get("_cb_search_token");
		if (!Token) {
			setIsLogged(false);
		} else {
			getUserDetail()
			//getWebsiteDetail()
		}
	}, [])

	const getUserDetail = () => {
		setUserLoader(true)
		setWebsiteLoader(true)
		PostData("ms1", "api/v1/user/getUserDetail").then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					setUserData(result.data.user)
					if (result.data.websites && Array.isArray(result.data.websites) && result.data.websites.length > 0) {
						if (!Cookies.get("_website_id")) {
							Cookies.set('_website_id', result.data.websites[0].websiteId)
							setCurrentWebsiteId(result.data.websites[0].websiteId)
						}else{
							setCurrentWebsiteId(Cookies.get("_website_id"))
						}
						let websitesLocal = result.data.websites,
							websiteDataLocal = {}
						for (let i = 0; i < websitesLocal.length; i++) {
							let currentWebsite = websitesLocal[i]
							websiteDataLocal[currentWebsite.websiteId] = currentWebsite
						}
						setWebsiteData(websiteDataLocal)
					}

				}
			}
			setWebsiteLoader(false)
			setUserLoader(false)
		});
	}

	// const getWebsiteDetail = () => {
	// 	setWebsiteLoader(true);
	// 	PostData("ms1", "api/v1/user/getDetails").then(result => {
	// 		if (result !== "Invalid" && result !== undefined ) {
	// 			if( result.status === "success" ){
	// 				setWebsiteData(result.data.websites)
	// 			}
	// 		}
	// 		setWebsiteLoader(false)
	// 	});
	// }

	if (!isLogged) {
		return <Navigate to="/auth/login" replace />
	}

	return (
		<>
			<section className="applicationWrapper" ref={mainContent}>
				<div className="applicationContentWrap">
					<Sidebar />
					{!editorPageFlag &&
						<SearchSidebar />
					}
					<Content type="search" />
				</div>
				<Notification />
			</section>
		</>
	);
};

export default Search;
