import React, { useContext, useEffect, useState } from 'react';
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "react-switch";
import { Button, Col, Input, Row, Spinner } from "reactstrap";
import { PostData } from "./../../../../services/FetchData"
import { toast } from 'react-toastify';
import { ProductContext } from '../../../../store';
import DetailSkeleton from '../../../Skeleton/DetailSkeleton';

const AutoCompleteComponent = () => {
	const [loader, setLoader]	= useState(true),
		[saveLoader, setSaveLoader]	= useState(false),
		[autoCompleteConfig, setAutoCompleteConfig]	= useState(null),
		[toggleLoader, setToggleLoader]	= useState(false),
		{
			currentWebsiteId
		}	= useContext(ProductContext)

	useEffect(() => {
		getAutoCompleteConfig()
	}, [])

	const getAutoCompleteConfig = () => {
		PostData("ms2", "api/v1/magento/getAutoCompleteConfig", {websiteId: currentWebsiteId}).then(result => {
			if( result !== "Invalid" && result !== "undefined" ){
				if( result.status === "success" ){
					console.log(result.data)
					setAutoCompleteConfig(result.data.autoCompleteConfig)
					setLoader(false)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const toggleAutoComplete = () => {
		setToggleLoader(true)
		PostData("ms2", "api/v1/magento/toggleAutoComplete", {websiteId: autoCompleteConfig.websiteId, status: !autoCompleteConfig.status}).then(result => {
			if( result !== "Invalid" && result !== "undefined" ){
				if( result.status === "success" ){
					setAutoCompleteConfig({
						...autoCompleteConfig,
						status: !autoCompleteConfig.status
					})
					toast.success(result.message)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setToggleLoader(false)
		})		
	}

	const saveAutoCompleteConfig = () => {
		setSaveLoader(true)
		PostData("ms2", "api/v1/magento/saveAutoComplete", {...autoCompleteConfig}).then(result => {
			if( result !== "Invalid" && result !== "undefined" ){
				if( result.status === "success" ){
					toast.success(result.message)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setSaveLoader(false)
		})
	}

	const changeAutoCompleteConfig = (indexName, value) => {
		setAutoCompleteConfig({
			...autoCompleteConfig,
			[indexName]: value
		})
	}

	return (
		<React.Fragment>
			{loader ?
				<DetailSkeleton />
			: autoCompleteConfig &&
				<div className="search-options-card">
					<div className="card-header">
						<div className="title-text">
							<h3>Autocomplete</h3>
							<span>Display multiple panels of results from different data sources as soon as they start typing.</span>
						</div>
						<div className="switch-container">
							<span>{autoCompleteConfig.status ? "Enabled" : "Disabled"}</span>
							{toggleLoader ?
								<Spinner />
							:
								<Switch
									checked={autoCompleteConfig.status}
									onChange={toggleAutoComplete}
									onColor={"#9eb3fc"}
									onHandleColor={"#3c64f4"}
									handleDiameter={25}
									uncheckedIcon={false}
									checkedIcon={false}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
									height={15}
									width={40}
									className="react-switch"
									id="material-switch"
								/>
							}
						</div>
					</div>
					<div className="card-content">
						<form>
							<Row className="mb-4 align-items-center">
								<Col xs={12} md={4}>
									<label htmlFor="products-count">
										<h4>No Of Products Shown</h4>
									</label>
								</Col>
								<Col xs={12} md={6}>
									<div className="input-container">
										<Button type="button" className="btn-minus" onClick={() => changeAutoCompleteConfig("noOfProducts", autoCompleteConfig.noOfProducts-1)}>
											<FontAwesomeIcon icon={faMinus} />
										</Button>
										<Input type="number" id="products-count" value={autoCompleteConfig.noOfProducts} onChange={(e) => { if( !isNaN(parseInt(e.target.value, 10)) ){ changeAutoCompleteConfig("noOfProducts", e.target.value) } }}/>
										<Button type="button" className="btn-plus" onClick={() => changeAutoCompleteConfig("noOfProducts", autoCompleteConfig.noOfProducts+1)}>
											<FontAwesomeIcon icon={faPlus} />
										</Button>
									</div>
									<div className="buttonContext">
										<p className='mb-0'>Number of products to show in search. Defaults to 6.</p>
									</div>
								</Col>
							</Row>
							<Row className="mb-4 align-items-center">
								<Col xs={12} md={4}>
									<label htmlFor="products-count">
										<h4>Enable Category</h4>
									</label>
								</Col>
								<Col xs={12} md={6}>
									<div className="checkboxContainer">
										<Input type="checkbox" className="checkboxContainerInput" checked={autoCompleteConfig.enableCategory} onChange={() => changeAutoCompleteConfig("enableCategory", !autoCompleteConfig.enableCategory)} />
									</div>
									<div className="buttonContext">
										<p className='mb-0'>Displays category in the left column.</p>
									</div>
								</Col>
							</Row>
							{autoCompleteConfig.enableCategory &&
								<Row className="mb-4 align-items-center">
									<Col xs={12} md={4}>
										<label htmlFor="products-count">
											<h4>No Of Category Shown</h4>
										</label>
									</Col>
									<Col xs={12} md={6}>
										<div className="input-container">
											<Button type="button" className="btn-minus" onClick={() => changeAutoCompleteConfig("noOfCategories", autoCompleteConfig.noOfCategories-1)}>
												<FontAwesomeIcon icon={faMinus} />
											</Button>
											<Input type="number" id="products-count" value={autoCompleteConfig.noOfCategories} onChange={(e) => { if( !isNaN(parseInt(e.target.value, 10)) ){ changeAutoCompleteConfig("noOfCategories", e.target.value) } }}/>
											<Button type="button" className="btn-plus" onClick={() => changeAutoCompleteConfig("noOfCategories", autoCompleteConfig.noOfCategories+1)}>
												<FontAwesomeIcon icon={faPlus} />
											</Button>
										</div>
										<div className="buttonContext">
											<p className='mb-0'>Number of categories to show in search. Defaults to 3.</p>
										</div>
									</Col>
								</Row>
							}
							<Row className="mb-4 align-items-center">
								<Col xs={12} md={4}>
									<label htmlFor="products-count">
										<h4>Enable Pages</h4>
									</label>
								</Col>
								<Col xs={12} md={6}>
									<div className="checkboxContainer">
										<Input type="checkbox" className="checkboxContainerInput" checked={autoCompleteConfig.enablePage} onChange={() => changeAutoCompleteConfig("enablePage", !autoCompleteConfig.enablePage)} />
									</div>
									<div className="buttonContext">
										<p className='mb-0'>Displays page in the left column.</p>
									</div>
								</Col>
							</Row>
							{autoCompleteConfig.enablePage &&
								<Row className="mb-4 align-items-center">
									<Col xs={12} md={4}>
										<label htmlFor="products-count">
											<h4>No Of Pages Shown</h4>
										</label>
									</Col>
									<Col xs={12} md={6}>
										<div className="input-container">
											<Button type="button" className="btn-minus" onClick={() => changeAutoCompleteConfig("noOfPages", autoCompleteConfig.noOfPages-1)}>
												<FontAwesomeIcon icon={faMinus} />
											</Button>
											<Input type="number" id="products-count" value={autoCompleteConfig.noOfPages} onChange={(e) => { if( !isNaN(parseInt(e.target.value, 10)) ){ changeAutoCompleteConfig("noOfPages", e.target.value) } }}/>
											<Button type="button" className="btn-plus" onClick={() => changeAutoCompleteConfig("noOfPages", autoCompleteConfig.noOfPages+1)}>
												<FontAwesomeIcon icon={faPlus} />
											</Button>
										</div>
										<div className="buttonContext">
											<p className='mb-0'>Number of pages to show in search. Defaults to 2.</p>
										</div>
									</Col>
								</Row>
							}
							<Row className="mb-4 align-items-center">
								<Col xs={12} md={4}>
									<label htmlFor="products-count">
										<h4>Enable Query Suggestions</h4>
									</label>
								</Col>
								<Col xs={12} md={6}>
									<div className="checkboxContainer">
										<Input type="checkbox" className="checkboxContainerInput" checked={autoCompleteConfig.enableQuerySuggestion} onChange={() => changeAutoCompleteConfig("enableQuerySuggestion", !autoCompleteConfig.enableQuerySuggestion)} />
									</div>
									<div className="buttonContext">
										<p className='mb-0'>Displays Query Suggestions in the left column.</p>
									</div>
								</Col>
							</Row>
							{autoCompleteConfig.enableQuerySuggestion &&
								<Row className="mb-4 align-items-center">
									<Col xs={12} md={4}>
										<label htmlFor="products-count">
											<h4>Number Of Suggestions Shown</h4>
										</label>
									</Col>
									<Col xs={12} md={6}>
										<div className="input-container">
											<Button type="button" className="btn-minus" onClick={() => changeAutoCompleteConfig("noOfQuerySuggestion", autoCompleteConfig.noOfQuerySuggestion-1)}>
												<FontAwesomeIcon icon={faMinus} />
											</Button>
											<Input type="number" id="products-count" value={autoCompleteConfig.noOfQuerySuggestion} onChange={(e) => { if( !isNaN(parseInt(e.target.value, 10)) ){ changeAutoCompleteConfig("noOfQuerySuggestion", e.target.value) } }}/>
											<Button type="button" className="btn-plus" onClick={() => changeAutoCompleteConfig("noOfQuerySuggestion", autoCompleteConfig.noOfQuerySuggestion+1)}>
												<FontAwesomeIcon icon={faPlus} />
											</Button>
										</div>
									</Col>
								</Row>
							}
							<Row className="mb-4 align-items-center">
								<Col xs={12} md={4}>
									<label htmlFor="products-count">
										<h4>Show Price</h4>
									</label>
								</Col>
								<Col xs={12} md={6}>
									<div className="checkboxContainer">
										<Input type="checkbox" className="checkboxContainerInput" checked={autoCompleteConfig.showPrice} onChange={() => changeAutoCompleteConfig("showPrice", !autoCompleteConfig.showPrice)} />
									</div>
								</Col>
							</Row>
							<Row className="mb-4 align-items-center">
								<Col xs={12} md={4}>
									<label htmlFor="products-count">
										<h4>Show Out Of Stock Products</h4>
									</label>
								</Col>
								<Col xs={12} md={6}>
									<div className="checkboxContainer">
										<Input type="checkbox" className="checkboxContainerInput" checked={autoCompleteConfig.outOfStock} onChange={() => changeAutoCompleteConfig("outOfStock", !autoCompleteConfig.outOfStock)} />
									</div>
									<div className="buttonContext">
										<p className='mb-0'>Products marked as not in stock will show up in search results.</p>
									</div>
								</Col>
							</Row>
							<Row className="mb-4 align-items-center">
								<Col xs={12} md={4}>
									<label htmlFor="products-count">
										<h4>Show Product Description</h4>
									</label>
								</Col>
								<Col xs={12} md={6}>
									<div className="checkboxContainer">
										<Input type="checkbox" className="checkboxContainerInput" checked={autoCompleteConfig.showDescription} onChange={() => changeAutoCompleteConfig("showDescription", !autoCompleteConfig.showDescription)} />
									</div>
								</Col>
							</Row>
							{autoCompleteConfig.showDescription &&
								<Row className="mb-4 align-items-center">
									<Col xs={12} md={4}>
										<label htmlFor="products-count">
											<h4>Maximum Description Lines</h4>
										</label>
									</Col>
									<Col xs={12} md={6}>
										<div className="input-container">
											<Button type="button" className="btn-minus" onClick={() => changeAutoCompleteConfig("descriptionMaxLines", autoCompleteConfig.descriptionMaxLines-1)}>
												<FontAwesomeIcon icon={faMinus} />
											</Button>
											<Input type="number" id="products-count" value={autoCompleteConfig.descriptionMaxLines} onChange={(e) => { if( !isNaN(parseInt(e.target.value, 10)) ){ changeAutoCompleteConfig("descriptionMaxLines", e.target.value) } }}/>
											<Button type="button" className="btn-plus" onClick={() => changeAutoCompleteConfig("descriptionMaxLines", autoCompleteConfig.descriptionMaxLines+1)}>
												<FontAwesomeIcon icon={faPlus} />
											</Button>
										</div>
									</Col>
								</Row>
							}						
							<Row className="mb-4 align-items-center">
								<Col xs={12} md={4}>
									<label htmlFor="products-count">
										<h4>Show SKU</h4>
									</label>
								</Col>
								<Col xs={12} md={6}>
									<div className="checkboxContainer">
										<Input type="checkbox" className="checkboxContainerInput" checked={autoCompleteConfig.showSKU} onChange={() => changeAutoCompleteConfig("showSKU", !autoCompleteConfig.showSKU)} />
									</div>
								</Col>
							</Row>
							{/* <div className="form-group" style={{ alignItems: 'center', marginTop: '2rem' }}>
								<label htmlFor="products-count">
									<h4>Highlight Search Term</h4>
								</label>
								<div className="rightContainer">
									<div className="checkboxContainer">
										<input
											type="checkbox"
											className="checkboxContainerInput"
											value={productsCount}
											readOnly
										/>
									</div>

								</div>
							</div> */}
							{/* <div className="form-group" style={{ alignItems: 'center', marginTop: '2rem' }}>
								<label htmlFor="products-count">
									<h4>Show Vendor</h4>
								</label>
								<div className="rightContainer">
									<div className="checkboxContainer">
										<input
											type="checkbox"
											className="checkboxContainerInput"
											value={productsCount}
											readOnly
										/>
									</div>

								</div>
							</div> */}
							<Row className="mb-4 align-items-center">
								<Col xs={12} md={4}>
									<label htmlFor="products-count">
										<h4>See All Button</h4>
									</label>
								</Col>
								<Col xs={12} md={6}>
									<div className="checkboxContainer">
										<Input type="checkbox" className="checkboxContainerInput" checked={autoCompleteConfig.seeAllButton} onChange={() => changeAutoCompleteConfig("seeAllButton", !autoCompleteConfig.seeAllButton)} />
									</div>
								</Col>
							</Row>
							<Row className='mb-4 align-items-start'>
								<Col xs={12} md={4}>
									<label htmlFor='minimumCharLength' className='mt-0 mt-md-3'>
										<h4>Minimum query length</h4>
									</label>
								</Col>
								<Col xs={12} md={6}>
									<div className="input-container">
										<Button type="button" className="btn-minus" onClick={() => changeAutoCompleteConfig("minimumCharLength", autoCompleteConfig.minimumCharLength-1)}>
											<FontAwesomeIcon icon={faMinus} />
										</Button>
										<Input type="number" id="products-count" value={autoCompleteConfig.minimumCharLength} onChange={(e) => { if( !isNaN(parseInt(e.target.value, 10)) ){ changeAutoCompleteConfig("minimumCharLength", e.target.value) } }}/>
										<Button type="button" className="btn-plus" onClick={() => changeAutoCompleteConfig("minimumCharLength", autoCompleteConfig.minimumCharLength+1)}>
											<FontAwesomeIcon icon={faPlus} />
										</Button>
									</div>
									<div className="buttonContext">
										<p className='mb-0'>The minimal number of characters required for a search term before a request is sent to the ConversionBox. If set to 0 then results will be fetched on first keystroke. Default value is 0.</p>
									</div>
								</Col>
							</Row>
							<Row className='justify-content-end'>
								<Col xs={"auto"}>
									<Button type='button' color='primary' onClick={() => saveAutoCompleteConfig()} disabled={saveLoader}>
										{saveLoader ?
											<Spinner size={"sm"} />
										:
											<>Save</>
										}
									</Button>
								</Col>
							</Row>
						</form>
					</div>
				</div>
			}
		</React.Fragment>
	);
};

export default AutoCompleteComponent;